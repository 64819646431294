import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Invoice = {};

Invoice.Get = async (invoice_no) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getInvoice, { invoice_no : invoice_no }));
		const item = ref.data.getInvoice;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Invoice.Query = async (invoice_flag, start_date, end_date) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryInvoice, {
											invoice_flag : invoice_flag,
											start_date : start_date,
											end_date : end_date
										}));
		let items = ref.data.queryInvoice.items;
		while (ref.data.queryInvoice.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryInvoice, {
												invoice_flag : invoice_flag,
												start_date : start_date,
												end_date : end_date,
												nextToken: ref.data.queryInvoice.nextToken
											}));
			items = items.concat(ref.data.queryInvoice.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Invoice.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateInvoice, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


Invoice.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createInvoice, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Invoice.Delete = async (invoice_no) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteInvoice, { invoice_no: invoice_no }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

export default Invoice;


