import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { useParams, withRouter } from 'react-router-dom';

const firstPageNum = 15;
const secondPageNum = 30;

const Default = () => {

	const { invoice_no } = useParams();
	const [load, setLoad] = useState(true);
	const [page, setPage] = useState([]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			const getInvoice = async (invoice_no, flag) => {
			
				const getPageData = async (data, flag) => {
				
					const newData = Object.assign({}, data);
					
					const PageData = [];
					
					//1ページ目のデータ
					const FirstPageData = Object.assign({}, newData);
					const FirstPageDetails = [];
					const SecoundPageDetails = [];
					let i = 0;
					for(const item of newData.details){
						if(i++ < firstPageNum){
							FirstPageDetails.push(item);
						}
						else{
							SecoundPageDetails.push(item);
						}
					}
					FirstPageData.details = FirstPageDetails;
					FirstPageData.page = 1;
					PageData.push(FirstPageData);
					
					//2ページ目のデータ
					if(SecoundPageDetails.length){
						for(const page of [...Array(Math.ceil(SecoundPageDetails.length / secondPageNum)).keys()].map(i => ++i)){
							const SecoundData = Object.assign({}, newData);
							const PageDetails = [];
							let j = 0;
							for(const item of SecoundPageDetails){
								j++;
								
								if(j > Number(secondPageNum * (page - 1)) &&j <= Number(secondPageNum * page)){
									PageDetails.push(item);
								}
							}
							SecoundData.details = PageDetails;
							SecoundData.page = Number(page + 1);
							PageData.push(SecoundData);
						}
					}
					
					//MAXページ
					const max_page = PageData.slice(-1)[0].page;
					const newPageData = [];
					for(const page of PageData){
						page.max_page = max_page;
						newPageData.push(page);
					}

					//最終ページが奇数だった場合空ページを差し込む
					if( (max_page % 2) !== 0 && flag){
						newPageData.push({empty_page : true});
					}
					return(newPageData);

				};

				//データ取得
				const item = (await API.Invoice.Get(invoice_no)).item;
				
				item.tax_subs = JSON.parse(item.tax_subs??'[]');

				//テスト
				/*
				item.details = item.details.concat(item.details);
				item.details = item.details.concat(item.details);
				item.details = item.details.concat(item.details);
				const newDetail = [];
				let z = 0;
				for(const detail of item.details){
					z++;
					if(z <= 75){
						newDetail.push(detail);
					}
				}
				item.details = newDetail;
				*/
				
				//ソートとページ作成
				if(item.details && item.details.length){
					item.details.sort(function(a,b){
						if(a.sort < b.sort) return -1;
						if(a.sort > b.sort) return 1;
						return 0;
					});
					return(await getPageData(item, flag));
				}
				else{
					return([]);
				}
			}
			
			let pageData = [];
			
			if(invoice_no === 'select'){
				//データ複数件の場合
				const json_text = sessionStorage.getItem('invoice_nos');
				if(json_text){
					for(const invoice_no of JSON.parse(json_text)){
						pageData = (await getInvoice(invoice_no, true)).concat(pageData);
					}
				}
			}
			else{
				//データ一件の場合
				pageData = await getInvoice(invoice_no);
			}
			
			setPage(pageData);
			
			//タイトル設定
			window.document.title = 'ご請求書' + (invoice_no === 'select' ? '' : '_' + invoice_no);

			//表示開始
			setLoad(false);
			
			//削除
			sessionStorage.removeItem('print');
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[true]);
	
	if(load){
		return (
			<div style={{ marginTop: 200, width: '100%', textAlign: 'center'}}>
			        <link rel="stylesheet" type="text/css" media="screen" href="/css/invoice/report_screen.css" />
			        <link rel="stylesheet" type="text/css" media="print" href="/css/invoice/report_print.css" />
				<img src="/img/loading.gif" alt="loading" />
			</div>
		);
	}
	else{
		return (
			<div>
			        <link rel="stylesheet" type="text/css" media="screen" href="/css/invoice/report_screen.css" />
			        <link rel="stylesheet" type="text/css" media="print" href="/css/invoice/report_print.css" />

				<form className="no_print" style={{ width: '100%', textAlign: 'center', marginTop: 50, marginBottom: 20}}>
					<input type="button" value={'請求書に戻る'} onClick={()=>{ window.location.href = '/invoice/'; }} />
				</form>
				
	                    	{page.map(data => { return(<Page data={data} />); })}
			</div>
		);
	}
};

const Page = (props) =>{
	
	const data = props.data;
	
	const dateShapingDate = (datetime) => {
		if(datetime){
			const dateObj      = new Date(Number(datetime * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			return(year + '年' + month + '月' + day + '日');
		}
		else{
			return('');
		}
	};
	
	if(data.empty_page){
		return(
			<div className="content">
				<div className="page">
				　<font color="SNOW">ONOCOMM</font>
				</div>
			</div>
		);
	}
	else{
		return(
			<div className="content" key={data.page}>
			
				<div className="page">
			                
			                <table className="invoice_separate" style={{display: data.page === 1 ? '' : 'none' }}>
			                <tbody>
			                    <tr>
			                        <td className="cell">
							<div className="address">
							〒{data.zip}<br/>
							{data.addr_1}{data.addr_2}<br/>
							{data.addr_3 ? <div>{data.addr_3}<br/></div> : ''}
							<br/>
							{data.customer_name}　{data.name ? '' : '御中'}<br/>
							{data.section ? <div>{data.section}<br/></div> : ''}
							{data.name ? <div>{data.name}　様<br/></div> : ''}
							（{data.customer_no}）
							</div>
			                        </td>
			                        <td className="space"></td>
			                        <td className="cell">
							<p className="text-right">
								発行日　{dateShapingDate(data.date_add)}<br/>
								（{data.invoice_no}）
							</p>
							<p className="invoice_head">ご利用代金請求明細書</p>
							<p className={data.seal_flag ? 'publisher_seal' : 'publisher'}>
								<img src="/img/logo.png" alt="株式会社オノコム" /><br/>
								{
									data.invoice_version === '2023-10-23'
										&&
									<><span>登録番号 : {data.eligible_invoice ?? '-------------'}</span><br/></>
								}
								〒530-0005<br/>
								大阪市北区中之島4 丁目3 番25 号<br/>
								フジヒサFJ 中之島ビル11 階<br/>
								TEL : 06-6441-6030 FAX : 06-6441-6031<br/>
								株式会社オノコム
							</p>
			                        </td>
			                    </tr>
			                    </tbody>
			                </table>
			                
					<div style={{ width: '100%', textAlign: 'right', display: data.page !== 1 ? '' : 'none' }}>
						（{data.invoice_no}）
					</div>

					<h2 style={{display: data.page === 1 ? '' : 'none' }}>ご請求情報</h2>
					
			                <table className="invoice_separate" style={{display: data.page === 1 ? '' : 'none' }}>
		                            <tbody>
			                    <tr>
			                        <td className="cell">
			                            <table className="invoice_info_left">
			                            <tbody>
			                            <tr>
			                                <th>ご請求金額</th>
			                                <td>{data.total_price.toLocaleString()} 円</td>
			                            </tr>
			                            <tr>
			                                <th>ご請求締日</th>
			                                <td>{dateShapingDate(data.date_invoice)}</td>
			                            </tr>
		                                    <tr>
		                                        <th>{data.pay_kbn === 'クレジットカード決済' ?'ご決済予定日' : data.pay_kbn === '銀行自動振替' ? 'お振替予定日' : 'お支払い期限'}</th>
		                                        <td>{dateShapingDate(data.date_payment)}</td>
		                                    </tr>
		                                    </tbody>
		                                  </table>
			                        </td>
			                        <td className="space"></td>
			                        <td className="cell">
			                          <table className="invoice_info_right">
			                          <tbody>
			                            <tr>
			                              <td>
			                                ＜お支払いについて＞<br/>
			                                {
			                                	(data.pay_kbn === 'クレジットカード決済' ? 'ご利用金額につきまして、ご指定のクレジットカード会社より請求させていただきます。\n' :
			                                	data.pay_kbn === '銀行自動振替' ? 'ご請求金額につきまして、次回振替予定日に指定口座より口座振替させていただきます。\n' :
			                                	'下記の銀行口座までお振込み下さい。\n' + data.bank_pay_text + '\n口座名義　株式会社オノコム\n※振込手数料はお客様負担でお願い致します。').split('\n').map(text => {return(<div>{text}<br/></div>);})
			                                }
							</td>
			                            </tr>
			                            </tbody>
			                            </table>
			                        </td>
			                    </tr>
		                            </tbody>
			                </table>
			                
	                		<h2>ご請求明細</h2>
	                		
			                <table className="t1" cellSpacing="0">
			                    <col width="50%" />
			                    <col width="10%" />
			                    <col width="15%" />
			                    <col width="15%" />
			                    <col width="10%" />
			                    <thead>
			                        <tr>
			                            <th>項目</th>
			                            <th>数量</th>
			                            <th>単価</th>
			                            <th>金額</th>
			                            <th>備考</th>
			                        </tr>
			                    </thead>
			                    <tbody>
			                    	{data.details.map(item => {
			                    	
			                    		return(
				                            <tr key={item.sort}>
				                                <td className="text-left">　{item.details}</td>
				                                <td className="text-right">{item.piece === 0 ? '' : item.piece} {item.piece === 0 ? '' : item.piece_type}　</td>
				                                <td className="text-right">{item.piece === 0 ? '' : item.price_notax.toLocaleString()}　</td>
				                                <td className="text-right">{item.piece === 0 ? '' : item.sub_price_notax.toLocaleString()}　</td>
				                                <td className="text-center">{item.piece === 0 ? '' : (item.tax_per !== '0' ? '課税' + (item.tax_per * 100) + '%' : item.piece === 0 ? '' : '非課税')}</td>
				                            </tr>
			                    		);
			                    	
			                    	})}
			                    </tbody>
			                </table>
			                
							{
								data.invoice_version === '2023-10-23'
									?
								<table className="t2" cellSpacing="0" style={{display: data.page === data.max_page ? '' : 'none' }}>
									<col width="34%" />
									<col width="22%" />
									<col width="22%" />
									<col width="22%" />
									<tbody>
									<tr>
										<td className="space">　</td>
										<td className="price">小計　{data.total_price_notax.toLocaleString()} 円 </td>
										<td className="price">消費税　{data.total_tax.toLocaleString()} 円 </td>
										<td className="price">合計　{data.total_price.toLocaleString()} 円 </td>
									</tr>
									{
										data.tax_subs.length !== 0
											&&
										<tr>
											<td className="space">　</td>
											<td>（内訳）</td>
										</tr>
									}
									{
										data.tax_subs.map(item => {
											return(
												<tr>
													<td className="space">　</td>
													<td className="price">{Number(item.rate)*100}%対象　{item.price.toLocaleString()} 円 </td>
													<td className="price">消費税　{item.tax.toLocaleString()} 円 </td>
													<td className="price"></td>
												</tr>
											);
										})
									}
									</tbody>
								</table>
									:
									<table className="t2" cellSpacing="0" style={{display: data.page === data.max_page ? '' : 'none' }}>
									<col width="60%" />
									<col width="9%" />
									<col width="6%" />
									<col width="15%" />
									<col width="10%" />
									<tbody>
										<tr>
											<td rowSpan="3" className="memo">
												＜ご案内＞<br/>
												本請求書は、オノコムポータルサイト（https://portal.onocomm.jp/）からもご覧いただけます。
												利用方法についてご不明な場合は、弊社までお問い合わせください。
											</td>
											<td rowSpan="3" className="space">　</td>
											<td className="sum">小計</td>
											<td className="price">{data.total_price_notax.toLocaleString()} 円 </td>
											<td></td>
										</tr>
										<tr>
											<td className="sum">消費税</td>
											<td className="price">{data.total_tax.toLocaleString()} 円 </td>
											<td></td>
										</tr>
										<tr>
											<td className="sum">合計</td>
											<td className="price">{data.total_price.toLocaleString()} 円 </td>
											<td></td>
										</tr>
									</tbody>
								</table>
							}
			                
			                <table className="invoice_separate">
			                    <tbody>
			                    <tr>
			                        <td className="text-center">{data.page} / { data.max_page} ページ</td>
			                    </tr>
		                            </tbody>
			                </table>
			                
		        	        <table className="invoice_separate"  style={{display: (data.page < data.max_page) && ( ( data.page % 2 ) !== 0 )  ? '' : 'none' }}>
			                    <tbody>
			                    <tr>
			                        <td className="text-right">（明細に続きがございます。裏面もご覧ください。）</td>
			                    </tr>
		                            </tbody>
			                </table>
			        </div>
			</div>
		);
	}
};

export default withRouter(Default);
