import { useState, useEffect  } from 'react';
import {
	Modal,
	Row,
	Col,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import Table from './Table';


const ModalAlert = (props) => {

	const [show, setShow] = useState(false);


	useEffect(() => {
		
		//表示開始
		if(props.data.length){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.data]);

	const handleClose = () => {
		setShow(false);
	};

	return (
		<Modal show={show} onHide={props.onClick ? props.onClick : handleClose} centered>
			<Modal.Body>
				{props.data.map((msg, index) => {return (<p key={index} style={{margin: "0px"}}>{msg}</p>);})}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={props.onClick ? props.onClick : handleClose}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const ModalCustomer = (props) => {

	const [show, setShow] = useState(false);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
			setData(props.data);
			setView(props.data);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);

	const handleClose = () => {
		setShow(false);
	};

	const handleReset = () => {
		props.onSelect('','');
	};

	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => props.onSelect(row.customer_no, row.customer_name)}>
					選択
				</Button>
			</div>
		);
	};

	const columns = [
		{
			dataField: 'dummy',
			isDummyField: true,
			text: '選択',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'customer_no',
			text: '顧客番号',
			sort: true,
		},
		{
			dataField: 'customer_name',
			text: '顧客名',
			sort: true,
		},
		{
			dataField: 'invoice_text',
			text: 'メモ',
			sort: true,
		},
	];

	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	return (
		<Modal show={show} onHide={handleClose} size="lg">
			<Modal.Body>
				<Row>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={2} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={2}>
									<Button variant="dark" type="button" block onClick={handleReset} >Reset</Button>
								</Col>
								<Col sm={2}>
									<Button variant="secondary" type="button" block onClick={handleClose} >Close</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Table keyField={'customer_no'} data={view} columns={columns} checkbox={false} />
			</Modal.Body>
		</Modal>
	);
};

export { ModalAlert, ModalCustomer };

