import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormButton } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { domain_name } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [showProtectFlag, setShowProtectFlag] = useState([false]);
	const [showStatus, setShowStatus] = useState([false]);
	const [showMailAlert, setShowMailAlert] = useState(false);
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.Mail.Get(domain_name)).item;

			if(!Object.keys(newData).length){
				setNotfound(true);
			}

			//初期値など設定
			newData.disk_alert_flag = newData.disk_alert_flag ? true : false;
			newData.disk_soft = Number(newData.disk_soft ?? 85);
			newData.disk_hard = Number(newData.disk_hard ?? 95);
			
			setData(newData);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	const updateProtectFlag = async (event) => {
	
		setShowProtectFlag([false]);
		
		try {
			
			if(domain_name){
			
				const newData ={
					domain_name : domain_name,
					protect_flag : data.protect_flag ? false : true,
				};
				
				//更新
				const ref = await API.Mail.Update(newData);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('削除保護を変更しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const updateStatus = async (event) => {
	
		setShowStatus([false]);
		
		try {

			if(data.protect_flag){
				throw new Error('解約を行うには削除保護を無効にしてください。');
			}
			
			if(data.status !== '有効'){
				throw new Error('ステータスが有効時以外操作できません。');
			}
			
			if(domain_name){
			
				const newData ={
					domain_name : domain_name,
					status : '解約申請中',
				};
				
				//更新
				const ref = await API.Mail.Update(newData);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('解約を申請しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};

	const updateMailAlert = async () => {

		setShowMailAlert(false);
		
		try {
			
			if(domain_name){
			
				const param ={
					domain_name : domain_name,
					disk_alert_flag : data.disk_alert_flag,
					disk_alert_to : data.disk_alert_to,
					disk_soft : data.disk_soft,
				};

				console.log(param);

				//更新
				const ref = await API.Mail.Update(param);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('アラートの設定を変更しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	/*
	const handleProtectFlagShow = () => {
		setShowProtectFlag([true]);
	};
	*/
	
	/*
	const handleStatusShow = () => {
		setShowStatus([true]);
	};
	*/

	const handleMailAlert = () => {
		setShowMailAlert(true);
	};		
	
	const handleClose = () => {
		setAlert([]);
		//setShowProtectFlag([false]);
		//setShowStatus([false]);
		setShowMailAlert(false);
		handleReload();
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalProtectFlag data={data} show={showProtectFlag} onClick={updateProtectFlag} onHide={handleClose} />
				<ModalStatus data={data} show={showStatus} onClick={updateStatus} onHide={handleClose} />
				<ModalMailAlert data={data} setData={setData} show={showMailAlert} handleSubmit={updateMailAlert} handleClose={handleClose} onHide={handleClose} />

				<Form name="form" >

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'ドメイン名'} name={'domain_name'} defaultValue={data.domain_name} readOnly={true} />
					<FormButton label={'管理画面'} value={'管理画面にログインする'} onClick={()=>{document.login.submit();}} disabled={data.status !== '有効'} />
					<FormButton label={'アラート設定'} value={'アラート設定を行う'} onClick={handleMailAlert} disabled={data.status !== '有効'}/>
					<hr/>
					<FormText label={'請求アカウント'} name={'customer_no'} defaultValue={data.customer_no + '　' + data.customer_name} readOnly={true} />
					<FormText label={'契約日'} name={'contract_date'} defaultValue={dateShapingDate(data.contract_date)} type={'date'} readOnly={true} />
					<FormText label={'パスワード'} name={'password'} defaultValue={data.password} type={'password'} onMouseOver={(event)=>{event.target.type='text';}} onMouseOut={(event)=>{event.target.type='password';}} readOnly={true} />
					<FormText label={'ディスク容量'} name={'disk'} defaultValue={(data.disk/1024).toLocaleString() + 'GB'} readOnly={true} />
					<FormText label={'使用率'} name={'disk_per'} defaultValue={(data?.disk_per ?? 0).toLocaleString() + '%'} readOnly={true} />
					<FormText label={'月額費用'} name={'price_notax'} defaultValue={data.price_notax.toLocaleString() + '円'} readOnly={true} />
					<hr/>
					<FormText label={'削除保護'} name={'protect_flag'} defaultValue={data.protect_flag ? '有効' : '無効'} readOnly={true} />
					{/*
					<FormButton label={'削除保護の切り替え'} value={'削除保護の切り替えを行う'} onClick={handleProtectFlagShow} disabled={data.status !== '有効'}/>
					<hr/>
					*/}
					<FormText label={'課金開始月'} name={'bill_start'} defaultValue={dateShapingMonth(data.bill_start)} type={'month'} readOnly={true} />
					<FormText label={'課金停止月'} name={'bill_end'} defaultValue={dateShapingMonth(data.bill_end)} type={'month'} readOnly={true} />
					<hr/>
					<FormText label={'ステータス'} name={'status'} defaultValue={data.status} readOnly={true} />
					<hr/>
					{/*
					<FormButton label={'解約手続き'} value={'解約手続きを行う'} onClick={handleStatusShow} disabled={data.status !== '有効'}/>
					<hr/>
					*/}
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>
				<form action="https://mail1.onocomm.jp/mail.cgi?" method="POST" name="login" target="_blank">
				<input type="hidden" name="flag" value="login"/>
				<input type="hidden" name="domain_name" value={data.domain_name}/>
				<input type="hidden" name="password" value={data.password}/>
				</form>
			</Container>
		);
	}
};

const ModalProtectFlag = (props) => {

	const [show, setShow] = useState(false);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	return (
		<Modal show={show} onHide={props.onHide} size="lg">
			<Modal.Body>
				<Form>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} disabled={props.data.status !== '有効'}>{props.data.protect_flag ? '無効にする' : '有効にする'}</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const ModalStatus = (props) => {

	const [show, setShow] = useState(false);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	return (
		<Modal show={show} onHide={props.onHide} size="lg">
			<Modal.Body>
				<Form>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} disabled={props.data.protect_flag || props.data.status !== '有効'} >解約する</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const ModalMailAlert = (props) => {

	const handleSubmit = (event) => {
		event.preventDefault();
		props.handleSubmit();
	};

	const handleChange = (event) => {

		const data = structuredClone(props.data);  

		const name = event.target.name;
		const value = event.target.value;
		console.log(name);
		console.log(value);

		if(name === 'disk_alert_flag'){
			data.disk_alert_flag = value === 'true' ? true : false;
		}
		else{
			data[name] = value;
		}

		props.setData(data);
	};
	
	return (
		<Modal show={props.show} onHide={props.onHide} size="lg" centered>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					アラートの設定
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={3}>
							有効・無効
						</Col>
						<Col sm={6}>
							<input type="radio" name="disk_alert_flag" value="true" onChange={handleChange} disabled={props.data.status !== '有効'} checked={props.data.disk_alert_flag} required/>　有効にする　
							<input type="radio" name="disk_alert_flag" value="false" onChange={handleChange} disabled={props.data.status !== '有効'} checked={!props.data.disk_alert_flag} required/>　無効にする
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>							
						<Col sm={3}>
							アラートの送信先
						</Col>
						<Col sm={6}>
							<Form.Control type="email" name="disk_alert_to" multiple value={props.data.disk_alert_to} onChange={handleChange} disabled={props.data.status !== '有効' || !props.data.disk_alert_flag} required={props.data.disk_alert_flag}/>
							<p><small>※カンマ区切りで複数のメールアドレスを登録出来ます。</small></p>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>							
						<Col sm={3}>
							使用率の通知
						</Col>
						<Col sm={3}>
							<Form.Control type="number" name="disk_soft" value={props.data.disk_soft} min={1} max={100} step={1} onChange={handleChange} disabled={props.data.status !== '有効' || !props.data.disk_alert_flag} required={props.data.disk_alert_flag}/>
						</Col>
						<Col sm={3}>
							％ 以上で通知
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>							
						<Col sm={3}>
							使用率の警告
						</Col>
						<Col sm={3}>
							<Form.Control type="number" name="disk_soft" value={props.data.disk_hard} onChange={handleChange} disabled={props.data.status !== '有効'} readOnly/>
						</Col>
						<Col sm={3}>
							％ 以上で警告
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<p><small>※警告の数値はシステムで固定されており変更は出来ません。<br/>
							※アラートの設定が無効の場合は、警告も送信されません。</small></p>
						</Col>
					</Row>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<Button variant="dark" type="submit" block disabled={props.data.status !== '有効'} >設定する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<Button variant="secondary" type="button" block onClick={props.handleClose} disabled={props.data.status !== '有効'} >キャンセル</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};


export default withRouter(Default);
