import { useEffect  } from 'react';
import { Button } from 'react-bootstrap';
import Encoding from 'encoding-japanese';

const Default = (props) => {


	useEffect(() => {
	
		try{
			const data = props.data;
			const skipHeader = props.skipHeader ? true : false;
			if(data.length !== 0){
				
				//ヘッダーの作成
				let header = [];
				for (const key in data[0]) {
					header.push(key);
				}
				
				//データ作成
				let csvText = '';
				let i = 0;
				for(const hash of data){
					i++;
					
					//ヘッダー作成
					if(i===1 && skipHeader === false){
						for (const key of header) {
							csvText = csvText + '"' + key.replace(/"/g,'""') + '",';
						}
						csvText = csvText.slice(0, -1) + '\r\n';
					}

					//内容作成
					for (const key of header) {
						const value = (hash[key] || '').toString().replace(/\n/g,'').replace(/\r/g,'').replace(/"/g,'""');
						csvText = csvText + '"' + value + '",';
					}
					csvText = csvText.slice(0, -1) + '\r\n';
				}
				//console.log(csvText);
				const unicodeList = [];
				for (let i = 0; i < csvText.length; i += 1) {
					unicodeList.push(csvText.charCodeAt(i));
				}
				const shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode');
				const uInt8List = new Uint8Array(shiftJisCodeList);
				const blob = new Blob([ uInt8List ], { "type" : "text/csv" });
				
				const url = window.URL.createObjectURL(blob);

				const link = document.createElement('a');
				link.download = props.filename;
				link.href = url;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		
		}
		catch(err){
			console.log(err);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.data]);
	
	const handleClick = () => {
		props.onClick();
	}
	
	return (
		<Button variant="dark" className={props.className} onClick={handleClick} >
			{props.children}
		</Button>
	);
};

export default Default;

