import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { dateShapingDate } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormTextArea } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { target, target_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [refresh, setRefresh] = useState([true]);
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//取得
			const orders = (await API.Order.GetAll()).items;
			
			if(target === 'quotation'){
				//データ取得
				const newData = (await API.Quotation.Get(target_no)).item;
				
				if(Object.keys(newData).length){
					newData.date_order = Math.floor((new Date()).getTime() / 1000);
					newData.biko_text = '';
					newData.is_order = false;
					//重複確認
					for(const item of orders){
						if(item.target === target && item.target_no === target_no){
							newData.is_order = true;
						}
					}
					setData(newData);
				}
				else{
					setNotfound(true);
				}
			}
			else{
				setNotfound(true);
			}
			
			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	const handleSubmit = async (event) => {
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		try {
		
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(!data.doui){
				throw new Error('文書への同意を行ってください。');
			}
			
			if(target && target_no && !data.is_order){
			
				//新規追加
				const ref = await API.Order.Create({
					order_no: data.order_no,
					customer_no: data.customer_no,
					customer_name: data.customer_name,
					title: data.title,
					i_total_price: data.i_total_price,
					i_total_price_notax: data.i_total_price_notax,
					i_total_tax: data.i_total_tax,
					m_total_price: data.m_total_price,
					m_total_price_notax: data.m_total_price_notax,
					m_total_tax: data.m_total_tax,
					biko_text: data.biko_text,
					target: target,
					target_no: target_no,
					date_order: data.date_order,
				});
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('発注しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		const check = event.target.checked;
		
		const newData = data;
		
		if(type === 'checkbox'){
			newData[name] = check;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='発注しました。'){
			history.goBack();
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Form onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>発注</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'発注対象番号'} name={'target_no'} defaultValue={target_no} readOnly={true} />
					<FormText label={'発注対象'} name={'title'} defaultValue={data.title} readOnly={true} />
					<FormText label={'初期費用'} name={'i_total_price_notax'} defaultValue={data.i_total_price_notax.toLocaleString() + '円'} readOnly={true} />
					<FormText label={'月額費用'} name={'m_total_price_notax'} defaultValue={data.m_total_price_notax.toLocaleString() + '円'} readOnly={true} />
					<hr/>
					<FormText label={'発注日'} name={'date_order'} defaultValue={dateShapingDate(data.date_order)} type={'date'} readOnly={true} />
					<FormText label={'発注アカウント'} name={'customer_no'} defaultValue={data.customer_no + '　' + data.customer_name}  readOnly={true} />
					<FormTextArea label={'連絡事項'} name={'biko_text'} value={data.biko_text}  onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 9, offset: 3 }}>
							<div className="alert alert-success" role="alert">
								ご発注を頂くにあたり、必ず以下の文書をご確認の上同意してください。<br/>
								また、ご発注内容において別途契約書がある場合はそちらが優先されます。<br/>
							</div>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 9, offset: 3 }}>
							<dl className="dl-horizontal">
								<dd><a href="https://www.onocomm.jp/support/pdf/service/contract_jp.pdf" target="_blank" rel="noreferrer">オノコムクラウドサービス契約約款 (https://www.onocomm.jp/support/pdf/service/contract_jp.pdf)</a></dd>
								<dd><a href="https://jprs.jp/pubcert/info/repository/" target="_blank" rel="noreferrer">サーバー証明書発行サービスに関するリポジトリ/利用規約 (https://jprs.jp/pubcert/info/repository/)</a></dd>
								<dd><a href="https://jprs.jp/doc/rule/rule-wideusejp.html" target="_blank" rel="noreferrer">ドメイン名の登録に関するルール (https://jprs.jp/doc/rule/rule-wideusejp.html)</a></dd>
								<dd><a href="https://jprs.jp/doc/rule/dom-data-handling.html" target="_blank" rel="noreferrer">JPドメイン名登録情報等の取り扱いについて (https://jprs.jp/doc/rule/dom-data-handling.html)</a></dd>
								<dd><a href="https://jprs.jp/doc/rule/disclose-rule.html" target="_blank" rel="noreferrer">公開・開示に関する規則 (https://jprs.jp/doc/rule/disclose-rule.html)</a></dd>
								<dd><a href="https://jprs.jp/doc/rule/disclose-list.html" target="_blank" rel="noreferrer">公開・開示対象一覧 (https://jprs.jp/doc/rule/disclose-list.html)</a></dd>
 							</dl>
							<input type="checkbox" name="doui" onChange={handleOnChange}/> 上記の文書に同意する。
						</Col>
					</Row>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={data.is_order}>発注する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
