import React from 'react';

const Default = () => {
	return (
		<div/>
	);
}

export default Default;

