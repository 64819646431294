import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
	Alert,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';


const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [alert, setAlert] = useState([]);
	const [showAddList, setShowAddList] = useState(false);
	const [showRemoveIP, setShowRemoveIP] = useState(null);

	useEffect(() => {
	
		const getData = async () => {
			
			//件数の取得
			const ref = await API.MailWhitelist.GetAll();
			
			const items = ref.items;

			//データセット
			setData(items);
			setView(items);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};

	const handleAdd = async (ip) => {

		//console.log(ip);

		setShowAddList(false);
		
		try {
			
			if(ip){
			
				const item = (await API.User.Get()).item;
				const customer_no = item.customer_no;

				const param ={
					ip : ip,
					customer_no : customer_no,
				};

				//console.log(param);

				//更新
				const ref = await API.MailWhitelist.Create(param);
				
				//エラー確認
				if(ref.error){
					console.log(ref.error);
					throw new Error('入力エラーがありました。すでに他のお客様が同じIPアドレスを登録されている可能性があります。');
				}
				
				throw new Error('ホワイトリストを追加しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};

	const handleRemove = async (ip) => {

		//console.log(ip);

		setShowRemoveIP(false);
		
		try {
			
			if(ip){
			
				//更新
				const ref = await API.MailWhitelist.Delete(ip);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('ホワイトリストから削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};		
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const handleClose = () => {
		setAlert([]);
		setShowAddList(false);
		setShowRemoveIP(null);
		handleReload();
	};

	const formatButton = (cell, row) => {
		return (
			<Button variant="dark" type="button" onClick={() => setShowRemoveIP(row.ip)}>
				削除
			</Button>
		);
	};
	
	const columns = [
		{
			dataField: 'dummy',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'ip',
			text: 'IPアドレス',
			sort: true,
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalAddList show={showAddList} handleSubmit={handleAdd} handleClose={handleClose} onHide={handleClose} />
				<ModalRemoveIP show={showRemoveIP} handleSubmit={handleRemove} handleClose={handleClose} onHide={handleClose} />

				<Row>
					<Col sm={12}>
						<h4>メールのホワイトリスト</h4>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Alert variant={'warning'}>
							<small>
							ここに登録されたIPアドレスは、メールサービスにおける通信ブロックの対象外となります。<br/>
							誤った認証を繰り返しても通信ブロックされることはなくなりますが、
							本来の正しいメールソフトの設定が行われなければメールサービスのパフォーマンスが低下したり、
							お客様の環境においてウイルスやマルウェアなどの発見が遅れる可能性があります。
							極力、ホワイトリストに頼らず、正しい設定を行うことをお勧めします。<br/>
							ホワイトリストに登録されているIPアドレスは、誤った認証を繰り返してもメールサービスの通信において遮断されることはありませんが、
							該当の認証チャレンジがあった場合はブロックデータベース上には記録されます。
							また、当社の判断でホワイトリストに登録されたIPアドレスを削除、無効化することがあります。
							あらかじめご了承ください。
							</small>
						</Alert>
					</Col>
				</Row>
				<Form>
					<Row style={{ marginTop: 20 }}>
						<Col sm={2}>
							<Button variant="danger" type="button" block onClick={() => setShowAddList(true)} >新規登録</Button>
						</Col>
					</Row>
				</Form>
				<Row>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={5} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'ip'} data={view} columns={columns} checkbox={false} sort={ {field: 'ip', order: 'asc'} } />
					</Col>
				</Row>
			</Container>
		);
	}
};

const ModalAddList = (props) => {

	const [ip, setIp] = useState('');

	useEffect(() => {
		setIp('');
	},[props.show]);

	const handleSubmit = (event) => {
		event.preventDefault();
		props.handleSubmit(ip);
	};

	return (
		<Modal show={props.show} onHide={props.onHide} size="lg" centered>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					ホワイトリストの追加
					<hr/>
					<Row style={{ marginTop: 10 }}>							
						<Col sm={3}>
							IPアドレス
						</Col>
						<Col sm={6}>
							<Form.Control type="text" name="ip" value={ip} onChange={(e)=>setIp(e.target.value)} pattern="\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}" placeholder="000.000.000.000" required/>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<p><small>※複数のIPアドレスを表すネットワークアドレス形式では入力できません。
							※すでに他のお客様が同じIPアドレスを登録されている場合は重複して登録出来ません。</small></p>
						</Col>
					</Row>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<Button variant="dark" type="submit" block >追加する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<Button variant="secondary" type="button" block onClick={props.handleClose} >キャンセル</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const ModalRemoveIP = (props) => {

	const handleSubmit = (event) => {
		event.preventDefault();
		props.handleSubmit(props.show);
	};

	return (
		<Modal show={props.show} onHide={props.onHide} size="lg" centered>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					ホワイトリストの削除
					<hr/>
					<Row style={{ marginTop: 10 }}>							
						<Col sm={3}>
							IPアドレス
						</Col>
						<Col sm={6}>
							<Form.Control type="text" name="ip" value={props.show} readOnly/>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<p><small>※上記に表示されたtIPアドレスをホワイトリストから削除しますか？</small></p>
						</Col>
					</Row>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<Button variant="dark" type="submit" block >削除する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							<Button variant="secondary" type="button" block onClick={props.handleClose} >キャンセル</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(Default);
