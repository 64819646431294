import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Alert,
	Container,
	Row,
	Col,
	Image,
	Card,
	CardDeck,
	ListGroup,
	Button,
	Badge,
	Modal,
} from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import { FaAngleDoubleRight } from "react-icons/fa";
import { ModalAlert } from '../../Objects/Modal';

const Default = () => {

	const document_url = 'https://www.onocomm.jp/support/pdf/service/contract_jp_aws_support_20240401.pdf';

	const { account_no } = useParams();
	const [load, setLoad] = useState(true);
	const [awsSupport, setAwsSupport] = useState({});
	const [disableFlag, setDisableFlag] = useState(true);
	const [changeAlert, setChangeAlert] = useState(null);
	const [selectPlan, setSelectPlan] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [billingDate, setBillingDate] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const [alert, setAlert] = useState([]);
	const [complete, setComplete] = useState(false);

	useEffect(() => {
	
		const getData = async () => {
			
			let flag = false;

			const aws_support = (await API.AWS.Get(account_no))?.item?.aws_support?.filter((item) => item?.status === '有効').sort((a, b) => b?.contract_date - a?.contract_date)?.[0] ?? {};

			//契約の有無を確認
			if(!Object.keys(aws_support).length){
				aws_support.support_name = 'サポートなし';
			}

			//契約日を確認
			if(aws_support.contract_date){
				const contract_date = new Date(aws_support.contract_date * 1000);
				const current_date = new Date();
				const contract_year = contract_date.getFullYear();
				const contract_month = contract_date.getMonth();
				const current_year = current_date.getFullYear();
				const current_month = current_date.getMonth();
				const com_a = new Date(contract_year, contract_month, 1);
				const com_b = new Date(current_year, current_month, 1);
				
				if (com_a >= com_b) {
					flag = true;
					setChangeAlert('現在ご契約中のサポートプランは課金開始月まで変更ができません。');
				}
			}

			//契約開始日と終了日を設定
			const end_date = new Date();
			const end_year = end_date.getFullYear();
			const end_month = end_date.getMonth() + 1;
			const billing_date = new Date();
			billing_date.setMonth(billing_date.getMonth() + 1);
			const billing_year = billing_date.getFullYear();
			const billing_month = billing_date.getMonth() + 1;

			setDisableFlag(flag);
			setAwsSupport(aws_support);
			setEndDate(end_year + '年' + end_month + '月');
			setBillingDate(billing_year + '年' + billing_month + '月');

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const handleChangePlan = (plan) => {
		if(selectPlan === plan){
			setSelectPlan(null);
		}
		else{
			setSelectPlan(plan);
		}
	};

	const getSupportPrice = (support_name) => {
		switch(support_name){
			case 'ベーシック':
				return '5,000円　か　AWS利用料の3％';
			case 'アドバンスド On-Ramp':
				return 'AWS利用料の25％';
			case 'アドバンスド':
				return '20,000円　か　AWS利用料の10％';
			case 'マネージド':
				return '100,000円　か　AWS利用料の3％〜20％';
			default:
				return 'なし';
		}
	};

	const getSupportContact = (support_name) => {
		switch(support_name){
			case 'ベーシック':
				return 'ポータルサイト';
			case 'アドバンスド On-Ramp':
				return '電話、メール、Slack、ポータルサイト';
			case 'アドバンスド':
				return '電話、メール、Slack、ポータルサイト';
			case 'マネージド':
				return 'お客様の指定のものに対応';
			default:
				return 'なし';
		}
	};

	const getSupportTecnical = (support_name) => {
		switch(support_name){
			case 'ベーシック':
				return '一般的なガイダンス';
			case 'アドバンスド On-Ramp':
				return 'お客様のユースケースに対応';
			case 'アドバンスド':
				return 'お客様のユースケースに対応';
			case 'マネージド':
				return 'お客様のアプリケーションに基づく';
			default:
				return 'なし';
		}
	};

	const getSupportResponse = (support_name) => {
		switch(support_name){
			case 'ベーシック':
				return '24時間';
			case 'アドバンスド On-Ramp':
				return '24時間';
			case 'アドバンスド':
				return '12時間';
			case 'マネージド':
				return '4時間';
			default:
				return 'なし';
		}
	};

	const getSupportMonitaring = (support_name) => {
		switch(support_name){
			case 'ベーシック':
				return 'なし';
			case 'アドバンスド On-Ramp':
				return 'なし';
			case 'アドバンスド':
				return 'なし';
			case 'マネージド':
				return 'あり';
			default:
				return 'なし';
		}
	};

	const judgeSupportDown = (support_name, new_support_name) => {
		switch(support_name){
			case 'サポートなし':
				return false;
			case 'ベーシック':
				switch(new_support_name){
					case 'サポートなし':
						return true;
					case 'ベーシック':
						return false;
					case 'アドバンスド On-Ramp':
						return false;
					case 'アドバンスド':
						return false;
					case 'マネージド':
						return false;
					default:
						return false;
				}
			case 'アドバンスド On-Ramp':
				switch(new_support_name){
					case 'サポートなし':
						return true;
					case 'ベーシック':
						return true;
					case 'アドバンスド On-Ramp':
						return false;
					case 'アドバンスド':
						return false;
					case 'マネージド':
						return false;
					default:
						return false;
				}
			case 'アドバンスド':
				switch(new_support_name){
					case 'サポートなし':
						return true;
					case 'ベーシック':
						return true;
					case 'アドバンスド On-Ramp':
						return true;
					case 'アドバンスド':
						return false;
					case 'マネージド':
						return false;
					default:
						return false;
				}
			case 'マネージド':
				switch(new_support_name){
					case 'サポートなし':
						return true;
					case 'ベーシック':
						return true;
					case 'アドバンスド On-Ramp':
						return true;
					case 'アドバンスド':
						return true;
					case 'マネージド':
						return false;
					default:
						return false;
				}
			default:
				return false;
		}
	};

	const handleSubmit = async (support_name) => {

		setAgreement(false);
		setLoad(true);
		
		try {

			
			const ref = await API.AWS.executeLambdaSupport(account_no, support_name);

			console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('エラーがありました。' + ref.error);
			}
			
			setComplete(true);
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLoad(false);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<Row>
					<Col sm={12}>
						<h4>サポートの変更</h4>
					</Col>
					{changeAlert ?
						<Alert variant="danger" onClose={() => setChangeAlert(null)} dismissible>
							{changeAlert}<br/>
						</Alert>
						:''}
					<Col sm={12}>
						<Row style={{ marginTop: 20 }}>
						<CardDeck>
							<Card>
								<Card.Body>
									<Card.Title>サポートなし<br/><div style={{ fontSize: '14px' }}>　</div></Card.Title>
									<Card.Subtitle className="mb-2 text-muted">{awsSupport.support_name === 'サポートなし' ? <Badge variant="danger">現在のプラン</Badge> : '　'}</Card.Subtitle>
									<Button variant={
										awsSupport.support_name === 'サポートなし' ? 'secondary' : 
										selectPlan === 'サポートなし' ? 'warning' : 'primary'
										} onClick={() => handleChangePlan('サポートなし')} disabled={disableFlag || awsSupport.support_name === 'サポートなし'}>このプランに変更</Button>
								</Card.Body>
								<Card.Body>
									<Card.Text>
										
									</Card.Text>
								</Card.Body>
							</Card>
							<Card>
								<Card.Body>
									<Card.Title>ベーシック<br/><div style={{ fontSize: '14px' }}>　</div></Card.Title>
									<Card.Subtitle className="mb-2 text-muted">{awsSupport.support_name === 'ベーシック' ? <Badge variant="danger">現在のプラン</Badge> : '　'}</Card.Subtitle>
									<Button variant={
										awsSupport.support_name === 'ベーシック' ? 'secondary' : 
										selectPlan === 'ベーシック' ? 'warning' : 'primary'
										} onClick={() => handleChangePlan('ベーシック')} disabled={disableFlag || awsSupport.support_name === 'ベーシック'}>このプランに変更</Button>
								</Card.Body>
								<Card.Body>
									<Card.Text style={{ fontSize: '14px' }}>										
										社内にエンジニアが在籍する場合におすすめ
									</Card.Text>
								</Card.Body>
							</Card>
							<Card>
								<Card.Body>
									<Card.Title>アドバンスド<br/><div style={{ fontSize: '14px' }}>On-Ramp</div></Card.Title>
									<Card.Subtitle className="mb-2 text-muted">{awsSupport.support_name === 'アドバンスド On-Ramp' ? <Badge variant="danger">現在のプラン</Badge> : '　'}</Card.Subtitle>
									<Button 
									variant={
										awsSupport.support_name === 'アドバンスド On-Ramp' ? 'secondary' : 
										selectPlan === 'アドバンスド On-Ramp' ? 'warning' : 'primary'
										} onClick={() => handleChangePlan('アドバンスド On-Ramp')} disabled={disableFlag || awsSupport.support_name === 'アドバンスド On-Ramp'}>このプランに変更</Button>
								</Card.Body>
								<Card.Body>
									<Card.Text style={{ fontSize: '14px' }}>										
										AWS利用料8万円までのスタートアップに
									</Card.Text>
								</Card.Body>
							</Card>
							<Card>
								<Card.Body>
									<Card.Title>アドバンスド<br/><div style={{ fontSize: '14px' }}>　</div></Card.Title>
									<Card.Subtitle className="mb-2 text-muted">{awsSupport.support_name === 'アドバンスド' ? <Badge variant="danger">現在のプラン</Badge> : '　'}</Card.Subtitle>
									<Button variant={
										awsSupport.support_name === 'アドバンスド' ? 'secondary' : 
										selectPlan === 'アドバンスド' ? 'warning' : 'primary'
										} onClick={() => handleChangePlan('アドバンスド')} disabled={disableFlag || awsSupport.support_name === 'アドバンスド'}>このプランに変更</Button>
								</Card.Body>
								<Card.Body>
									<Card.Text style={{ fontSize: '14px' }}>										
										あらゆるお客様に適切なサポートを
									</Card.Text>
								</Card.Body>
							</Card>
							<Card>
								<Card.Body>
									<Card.Title>マネージド<br/><div style={{ fontSize: '14px' }}>　</div></Card.Title>
									<Card.Subtitle className="mb-2 text-muted">{awsSupport.support_name === 'マネージド' ? <Badge variant="danger">現在のプラン</Badge> : '　'}</Card.Subtitle>
									<Button variant={
										awsSupport.support_name === 'マネージド' ? 'secondary' : 
										selectPlan === 'マネージド' ? 'warning' : 'primary'
										} onClick={() => handleChangePlan('マネージド')} disabled={disableFlag || awsSupport.support_name === 'マネージド'}>このプランに変更</Button>
								</Card.Body>
								<Card.Body>
									<Card.Text style={{ fontSize: '14px' }}>										
										お客様のプロジェクトチームの一員として
									</Card.Text>
								</Card.Body>
							</Card>
							</CardDeck>
						</Row>
					</Col>
				</Row>
				<p style={{ marginTop: 20, fontSize: '14px' }}>
				※サポートプランの詳細は<a href="https://www.onocomm.jp/support.html" target="_blank" rel="noreferrer">こちら</a>をご覧ください。
				サポートプランの契約約款は<a href={document_url} target="_blank" rel="noreferrer">こちら</a>をご覧ください。
				費用はすべて税別表記となります。
				</p>
				<hr/>

				<Row>
					<Col sm={5} style={{ marginTop: 20 }}>
						<Card>
							<Card.Body>
								<Card.Title>
									現在のプラン
									　　<Button style={{visibility : 'hidden'}}>　</Button>
								</Card.Title>
								{awsSupport.support_name === 'サポートなし' ?
								<ListGroup variant="flush">
									<ListGroup.Item>プラン名：{awsSupport.support_name}</ListGroup.Item>
								</ListGroup>
								:
								<ListGroup variant="flush">
									<ListGroup.Item>プラン名：{awsSupport.support_name}</ListGroup.Item>
									<ListGroup.Item>月額費用：{getSupportPrice(awsSupport.support_name)}</ListGroup.Item>
									<ListGroup.Item>サービス終了日：{selectPlan ? '本日まで' : 'ー' }</ListGroup.Item>
									<ListGroup.Item>課金終了月：{selectPlan ? endDate : 'ー'}</ListGroup.Item>
									<ListGroup.Item>連絡手段：{getSupportContact(awsSupport.support_name)}</ListGroup.Item>
									<ListGroup.Item>技術的なお問合せ：{getSupportTecnical(awsSupport.support_name)}</ListGroup.Item>
									<ListGroup.Item>応答時間：{getSupportResponse(awsSupport.support_name)}</ListGroup.Item>
									<ListGroup.Item>死活監視：{getSupportMonitaring(awsSupport.support_name)}</ListGroup.Item>
								</ListGroup>
							}
							</Card.Body>
						</Card>
					</Col>
					{selectPlan !== null ?
					<Col sm={1} className="text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<FaAngleDoubleRight size={50}/>
					</Col>
					: <div/>}
				{selectPlan !== null ?
						<Col sm={6} style={{ marginTop: 20 }}>
							<Card>
								<Card.Body>
									<Card.Title>
										変更のプラン
										　<Button variant={'danger'} onClick={() => setShowModal(true)}>申し込む</Button>
									</Card.Title>
										{selectPlan === 'サポートなし' ?
										<ListGroup variant="flush">
											<ListGroup.Item>プラン名：{selectPlan}</ListGroup.Item>
									</ListGroup>	
										:
										<ListGroup variant="flush">
											<ListGroup.Item>プラン名：{selectPlan}</ListGroup.Item>
											<ListGroup.Item>月額費用：{getSupportPrice(selectPlan)}</ListGroup.Item>
											<ListGroup.Item>サービス開始日：本日から</ListGroup.Item>
											<ListGroup.Item>課金開始月：{billingDate}</ListGroup.Item>
											<ListGroup.Item>連絡手段：{getSupportContact(selectPlan)}</ListGroup.Item>
											<ListGroup.Item>技術的なお問合せ：{getSupportTecnical(selectPlan)}</ListGroup.Item>
											<ListGroup.Item>応答時間：{getSupportResponse(selectPlan)}</ListGroup.Item>
											<ListGroup.Item>死活監視：{getSupportMonitaring(selectPlan)}</ListGroup.Item>
										</ListGroup>
										}
								</Card.Body>
							</Card>
						</Col>
						 : <div/>}
				</Row>

				<Modal show={showModal} onHide={() => setShowModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			    	<Modal.Header closeButton>
        				<Modal.Title id="contained-modal-title-vcenter">
				        	合意事項の確認
        				</Modal.Title>
				    </Modal.Header>
			    <Modal.Body>
					<iframe 
						title="契約約款"
						src={`${document_url}#page=1&scrollbar=1&view=FitH&toolbar=0&statusbar=0&messages=0&navpanes=0`}
						style={{ width: '100%', height: '500px' }}
					></iframe>
				
					<h4>約款の内容をよく読み合意してください</h4>
					<p>
						ドキュメントは<a href={document_url} target="_blank" rel="noreferrer">こちら</a>よりダウンロードできます。<br/>
						合意する場合は、下記のボタンをクリックしてください。<br/>
					</p>
			    </Modal.Body>
				<Modal.Footer>
					<Button variant={'danger'} onClick={() => {setShowModal(false);setAgreement(true);}}>合意する</Button>
					<Button onClick={()=>setShowModal(false)}>閉じる</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={agreement} onHide={()=>setAgreement(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Body>
					本当に合意して申し込みをしますか？<br/>
					<p>
					{judgeSupportDown(awsSupport.support_name, selectPlan) ? '当社は短期間にプラン切り替えを頻繁に行うお客様に対してサポートの提供を拒否する権利を留保します。ご注意ください。' : ''}
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={()=>handleSubmit(selectPlan)}>
						はい
					</Button>
					<Button variant="secondary" onClick={()=>setAgreement(false)}>
						いいえ
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={complete} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Body>
					変更が完了しました
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={()=>{window.location.href = '/aws/' + account_no}}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>

			<ModalAlert data={alert} onClick={()=>setAlert([])} />
			</Container>
		);
	}
};

export default withRouter(Default);
