import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Customer = {};

Customer.Get = async (customer_no) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getCustomer, { customer_no : customer_no }));
		const item = ref.data.getCustomer;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Customer.GetAll = async () =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanCustomer));
		let items = ref.data.scanCustomer.items;
		while (ref.data.scanCustomer.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanCustomer, { nextToken: ref.data.scanCustomer.nextToken }));
			items = items.concat(ref.data.scanCustomer.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Customer.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateCustomer, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Customer;
