import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const Default = () => {
	
	useEffect(() => {
	
		//削除
		sessionStorage.removeItem('print');
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[true]);
	
	return (
		<form style={{ width: '100%', textAlign: 'center', marginTop: 50}}>
			<input type="button" value={'移動する'} onClick={()=>{ window.location.reload(); }} />
		</form>
	);
};

export default withRouter(Default);

