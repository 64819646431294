import {
	Navbar,
	Nav,
	NavDropdown,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';

const Default = () => {

	const history = useHistory();

	const handleClick = (uri) => {
		if(uri){
			history.push(uri);
		}
	};
	return (
		<div>
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="no_print">
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav onSelect={handleClick} className="mr-auto">
						<Nav.Link eventKey={"/customer/"}>請求アカウント</Nav.Link>
						<Nav.Link eventKey={"/domain/"}>ドメイン名</Nav.Link>
						<Nav.Link eventKey={"/ssl/"}>サーバー証明書</Nav.Link>
						<Nav.Link eventKey={"/aws/"}>AWSクラウド</Nav.Link>
						<Nav.Link eventKey={"/server/"}>サーバー</Nav.Link>
						<NavDropdown title="メール" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/mail/"}>メールサービス</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/mail/whitelist/"}>ホワイトリスト</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link eventKey={"/ns53/"}>NS53</Nav.Link>
						<Nav.Link eventKey={"/bill/"}>月額課金</Nav.Link>
						<Nav.Link eventKey={"/quotation/"}>見積書</Nav.Link>
						<Nav.Link eventKey={"/invoice/"}>請求書</Nav.Link>
						<NavDropdown title="サポート" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/support/"}>新しいお問合せ</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/support/case/"}>過去のケース</NavDropdown.Item>
						</NavDropdown>
					</Nav>
					<Nav onSelect={handleClick}>
						<NavDropdown title="ログイン情報" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/account/"}>アカウント情報</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item eventKey={"/sign-out/"}>ログオフ</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link eventKey={"/"} style={{color:'white'}}>ver.3.6</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

//Version 2.6 AWSサポート変更のオンライン受付を追加
//Version 2.7 AWSサポートのサービス約款を変更　2024年4月1日第1版
//Version 3.0 2024年6月17日 AWSサポートのオンライン受付を追加、Bedrockによる自動応答を開始
//Version 3.1 2024年9月11日　メールサービスの使用率によるアラート機能を実装
//Version 3.2 2024年9月13日　メールサービスにおけるブロック機能のホワイトリスト機能を追加
//Version 3.3 2024年11月1日　AWS課金明細にCSVダウンロード機能を追加
//Version 3.4 2024年11月6日　サポートをオノコムが代行してオープンしたバージョンのケース表示に対応
//Version 3.5 2024年11月7日　AWS構築支援の情報を追加
//Version 3.6 2024年11月7日　エンジニアのプロフィール情報を追加

export default withRouter(Default);

