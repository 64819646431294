import { useState, useEffect  } from 'react';
import {
	Container,
	Row,
	Col,
	Image,
	Card,
	Form,
	Button,
	Badge,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../../Objects/Modal';
import API from '../../../@onocomm-system';

const document_url = 'https://www.onocomm.jp/support/pdf/service/contract_jp_aws_build_support_20240401.pdf';

const Default = () => {

	const [load, setLoad] = useState(true);
	const [alert, setAlert] = useState([]);
	const [confirmation, setConfirmation] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const [select, setSelect] = useState({});
	const [user, setUser] = useState({});
	const [customerData, setCustomerData] = useState([]);
	const [defaultAWSData, setDefaultAWSData] = useState([]);
	const [awsData, setAWSData] = useState([]);
	const [supportName, setSupportName] = useState('');
	const [accountNo, setAccountNo] = useState('');
	const [customerNo, setCustomerNo] = useState('');
	const [textValue, setTextValue] = useState('');
	const [mailCC, setMailCC] = useState('');
	const [case_id, setCaseId] = useState('');

	useEffect(() => {
	
		const getData = async () => {
			
			//ユーザ情報の取得
			const resultUser = await API.User.Get();
			const itemUser = resultUser.item;

			//AWS契約情報の取得
			const resultAWS = await API.AWS.GetAll();
			const itemAWS = resultAWS.items.filter(x=>x.status !== '無効');

			//契約情報からユニークの顧客情報を取得
			const customerGroup = Array.from(
				new Map(itemAWS.map(item => [item.customer_no, { ...item }])).values()
			).sort((a, b) => b?.contract_date - a?.contract_date);
			const customer_no = customerGroup?.[0]?.customer_no ?? '';

			setUser(itemUser);
			setCustomerNo(customer_no);
			setCustomerData(customerGroup);
			setDefaultAWSData(itemAWS);
			setLoad(false);
			
		};
		
		getData();
		
	},[]);

	useEffect(() => {

		const AWSGroup= defaultAWSData.filter(x=>x.customer_no === customerNo);	
		const AWSGroupEnableSupport = AWSGroup.filter(x=>x.aws_support?.filter((item) => item?.status === '有効').length > 0)?.[0] ?? {};
		const account_no = AWSGroupEnableSupport?.account_no ?? '';
		setAWSData(AWSGroup);
		setAccountNo(account_no);

	},[customerNo, defaultAWSData]);

	useEffect(() => {
	
		const support_name = defaultAWSData.filter(x=> x.account_no === accountNo)?.[0]?.aws_support?.filter((item) => item?.status === '有効').sort((a, b) => b?.contract_date - a?.contract_date)?.[0]?.support_name ?? '';
		setSupportName(support_name);

	},[accountNo, defaultAWSData]);

	useEffect(() => {
		if(case_id && accountNo){
			window.location.href = '/support/case/' + accountNo + '/' + case_id;
		}
	},[case_id, accountNo]);

	const handleConfirmation = async (event) => {

		event.preventDefault();
		event.stopPropagation();

		setLoad(true);
		
		try {

			setConfirmation(false);
			setAgreement(true);
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLoad(false);
	};

	const handleAgreement = async (event) => {

		event.preventDefault();
		event.stopPropagation();

		setLoad(true);
		
		try {

			setAgreement(false);

			const params = {
				user_name : user.user_name,
				account_no : accountNo,
				customer_no : customerNo,
				type : 'build',
				subject : '（' + select.name+ '） ' + select.title,
				text_value : textValue,
				build_id : select.name,
				build_title : select.title,
				build_price : select.price,
				build_period : select.period,
				build_type : select.type,
				build_off : select.off,
				build_description : select.description,
				mail_cc : mailCC,
			};

			const result = await API.AWSSupport.Create(params);

			if(result.error){
				throw new Error(result.error);
			}

			const case_id = result?.ref?.data?.createAWSSupport?.case_id;

			if(!case_id){
				throw new Error('ケースIDが取得できませんでした');
			}

			setCaseId(case_id);

		}
		catch(err){
			setAlert([err.message]);
			setLoad(false);
		}
	};

	const handleClose = () => {
		setConfirmation(false);
		setAgreement(false);
		setTextValue('');
	};

	const handleBack = () => {
		setAgreement(false);
		setConfirmation(true);
	};

	const optionData = [
		{
			name : 'OP-1',
			type : 'basic',
			color : 'primary',
			title : '2日間構築支援',
			description : '約2日間を目安とした構築支援を提供します。',
			price : 50000,
			period : 2,
		},
		{
			name : 'OP-2',
			type : 'basic',
			color : 'primary',
			title : '7日間構築支援',
			description : '約7日間を目安とした構築支援を提供します。',
			price : 100000,
			period : 7,
		},
		{
			name : 'OP-3',
			type : 'basic',
			color : 'primary',
			title : '14日間構築支援',
			description : '約14日間を目安とした構築支援を提供します。',
			price : 200000,
			period : 14,
		},
		/*
		{
			name : 'WEB-1',
			type : 'web',
			color : 'info',
			title : 'WEBサイト高速化パッケージ',
			description : 'WEBサイトにCDNとWAFの導入を含む構築支援を提供します。Cloud Front、WAF',
			price : 20000,
			period : 2,
			off : 20,
		},
		{
			name : 'WEB-2',
			type : 'web',
			color : 'info',
			title : 'オートスケール対応パッケージ',
			description : 'WEBサーバーが突然の負荷に対応出来るようロードバランサーによるオートスケール環境の構築支援を提供します。ELB、Auto Scaling Group、EC2、RDS、EFS',
			price : 70000,
			period : 5,
			off : 20,
		},
		*/
		/*
		{
			name : 'DEV-1',
			type : 'develop',
			color : 'warning',
			title : 'gitHubとCI/CDの構築支援',
			description : 'ソースコードのバージョン管理と共に自動的にデプロイが行えるgitHubとCI/CDの環境構築支援を提供します。Codepipeline、Codebuild、CodeDeploy、GitHub',
			price : 100000,
			period : 7,
		},
		{
			name : 'DEV-2',
			type : 'develop',
			color : 'warning',
			title : 'DBコネクションプールの構築支援',
			description : '大量接続やサーバーレスからの接続に対応するためのRDS接続対策と、セキュリティ向上を目的としたIAM認証及びSecretManagerによる接続詞の秘匿化の支援を提供します。RDS Proxy、IAM、SecretManager',
			price : 50000,
			period : 7,
		},
		{
			name : 'DEV-3',
			type : 'develop',
			color : 'warning',
			title : 'アプリケーションの冗長化環境への移行支援',
			description : 'WEBアプリケーションを冗長化環境へ移行する際に、問題となりやすいポイントや、動作検証のお手伝い、移行の支援を行います。ELB、EFS、EC2、RDS',
			price : 150000,
			period : 14,
		},
		{
			name : 'DEV-4',
			type : 'develop',
			color : 'warning',
			title : 'サーバーレスアプリケーションの移行支援',
			description : '既存のCGI、PHPによる簡易WEBアプリケーションをサーバーレスアプリケーションに置き換えるための構築支援を行います。Lambda、AppSync、DynamoDB、S3',
			price : 150000,
			period : 14,
		},
		{
			name : 'DEV-5',
			type : 'develop',
			color : 'warning',
			title : 'SQSによる非同期処理の構築支援',
			description : 'SQSを利用した非同期処理の構築支援を行います。キュータイプとLambdaの同時実行の調整、デッドレターキューの再処理の構築支援を行います。SQS、Lambda、EC2',
			price : 150000,
			period : 14,
		},
		{
			name : 'DEV-6',
			type : 'develop',
			color : 'warning',
			title : 'ゼロトラストセキュリティによる認証環境の構築支援',
			description : 'WEBアプリケーションにAWS Cognito及びIAMを利用したゼロトラストセキュリティによる認証環境の構築支援を行います。Cognito、IAM、Lambda',
			price : 150000,
			period : 14,
		},
		{
			name : 'DEV-7',
			type : 'develop',
			color : 'warning',
			title : 'AWS環境におけるアプリケーションの動作検証支援',
			description : '自社で開発したWEBアプリケーションをAWS環境に移行する際の動作検証支援を行います。ELB、EC2、RDS、S3、Route53',
			price : 150000,
			period : 14,
		},
		{
			name : 'ORG-1',
			type : 'architect',
			color : 'success',
			title : 'AWS Organizationsの導入支援',
			description : 'AWSアカウントをマルチアカウントへ移行する支援行います。AWS Organizations',
			price : 20000,
			period : 2,
		},
		{
			name : 'ORG-2',
			type : 'architect',
			color : 'success',
			title : 'AWS Organizations SCPの構築支援',
			description : 'AWS OrganizationsにおけるSCPのポリシー作成を行います。1ポリシーあたりを目安とし、1つのサービスにおける制限事項を対象とします。',
			price : 20000,
			period : 2,
		},
		{
			name : 'FS-1',
			type : 'architect',
			color : 'success',
			title : 'ファイルサーバーの構築支援',
			description : 'Windows環境で利用が出来るファイルサーバーの構築支援を行います。FSx',
			price : 250000,
			period : 14,
		},
		{
			name : 'CT-1',
			type : 'architect',
			color : 'success',
			title : 'コスト削減計画の立案',
			description : 'AWSクラウドのコスト削減計画を立案し、実行支援を行います。アドバンスドプランの方は年に1度、マネージドプランの方は適時、無料でご利用いただけます。Saving Plans、Reserved Instance、Cost Explorer、Trusted Advisor',
			price : 200000,
			period : 14,
		},
		{
			name : 'DEP-1',
			type : 'architect',
			color : 'success',
			title : '自動デプロイ環境の構築支援',
			description : 'AWS Cloudformationを利用した自動デプロイ環境の構築支援を行います。本オプション内では簡易なVPCとEC2のデプロイを想定します。Cloudformation、EC2、VPC',
			price : 50000,
			period : 2,
		},
		{
			name : 'ES-1',
			type : 'architect',
			color : 'success',
			title : 'ElasticSearchによるデータ可視化支援',
			description : 'Kibanaを利用したデータ可視化環境の構築支援を行います。ElasticSearch、Kibana、Cloud Watch',
			price : 50000,
			period : 2,
		},
		{
			name : 'MDN-1',
			type : 'modernize',
			color : 'dark',
			title : 'WEBサイトのAWS移行支援',
			description : 'PHP、Perl/CGIなどで稼働するLAMP環境のWEBサイトをAWSへ移行する支援を行います。EC2、RDS、Application Migration Service',
			price : 100000,
			period : 7,
		},
		{
			name : 'MDN-2',
			type : 'modernize',
			color : 'dark',
			title : 'WindowsサーバーのAWS移行支援',
			description : 'WindowsサーバーをAWSへ移行する支援を行います。EC2、RDS、Application Migration Service',
			price : 200000,
			period : 14,
		},
		{
			name : 'MDN-3',
			type : 'modernize',
			color : 'dark',
			title : 'DBサーバーのAWS移行支援',
			description : 'MySQL、PostgreSQLのDBサーバーのバージョンアップに対応しながらAWSへ移行する支援を行います。RDS、DMS、Application Migration Service',
			price : 50000,
			period : 2,
		},
		{
			name : 'MDN-4',
			type : 'modernize',
			color : 'dark',
			title : 'DBサーバーのチューニング支援',
			description : 'すでにAWSクラウド上で稼働中のMySQL、PostgreSQLのDBサーバーに対して、スロークエリーなどのボトルネックの調査及び移行を含むチューニング支援を行います。RDS、Aurora ServerlessV2、Cloud Watch',
			price : 200000,
			period : 14,
		},
		{
			name : 'WP-1',
			type : 'wordpress',
			color : 'danger',
			title : 'WordpressサイトのAWS移行支援',
			description : 'Wordpressで構築されたWEBサイトをAWSへ移行する支援を行います。EC2、RDS、S3、Route53',
			price : 50000,
			period : 2,
		},
		{
			name : 'WP-2',
			type : 'wordpress',
			color : 'danger',
			title : 'Wordpressサイトの高速化支援',
			description : 'Wordpressで構築されたWEBサイトの高速化支援を行います。Cloud Front、EC2、RDS',
			price : 50000,
			period : 2,
		},
		{
			name : 'WP-3',
			type : 'wordpress',
			color : 'danger',
			title : 'Wordpressサイトのセキュリティ対策支援',
			description : 'Wordpressで構築されたWEBサイトのセキュリティ対策支援を行います。Cloud Front、WAF、Cloud Watch',
			price : 50000,
			period : 2,
		},
		{
			name : 'WP-4',
			type : 'wordpress',
			color : 'danger',
			title : 'Wordpressサイトの冗長化支援',
			description : 'Wordpressで構築されたWEBサイトの冗長化支援を行います。ELB、EFS、EC2、RDS',
			price : 50000,
			period : 2,
		},
		{
			name : 'NET-1',
			type : 'network',
			color : 'secondary',
			title : 'メール送信環境の構築支援',
			description : 'WEBサーバー並びにクラウド環境からのメール送信環境の構築支援を行います。SES、Route53',
			price : 20000,
			period : 2,
		},
		{
			name : 'NET-2',
			type : 'network',
			color : 'secondary',
			title : '大量メール送信環境の構築支援',
			description : 'WEBサーバー並びにクラウド環境からの大量メール送信環境の構築支援を行います。バウンス及び苦情のトラップと管理方法の仕組みも提供します。SES、S3、Lambda、DynamoDB、Route53',
			price : 200000,
			period : 10,
		},
		{
			name : 'NET-3',
			type : 'network',
			color : 'secondary',
			title : '拠点間VPNの構築支援',
			description : '1拠点間とAWSクラウドをVPNで接続する環境の構築支援を行います。VPN Gateway、VPC、Route53',
			price : 100000,
			period : 14,
		},
		{
			name : 'NET-4',
			type : 'network',
			color : 'secondary',
			title : 'Direct Connectの構築支援',
			description : 'Direct Connectを利用してAWSクラウドへ接続する環境の構築支援を行います。Direct Connect、VPC、Route53',
			price : 100000,
			period : 90,
		},
		{
			name : 'NET-5',
			type : 'network',
			color : 'secondary',
			title : 'VPN Clintの構築支援',
			description : 'VPN Clientを利用してAWSクラウドへ接続する環境の構築支援を行います。VPN Client、VPC、Route53',
			price : 70000,
			period : 14,
		},
		{
			name : 'SEC-1',
			type : 'security',
			color : 'light',
			title : 'AWSセキュリティ対策パッケージ',
			description : 'AWSクラウドに対するセキュリティ対策及び監査対応を行います。IAM、Could Trail、IAM Identity Center、AWS Config、Security Hub、Cloud Watch',
			price : 100000,
			period : 14,
		},
		{
			name : 'SEC-2',
			type : 'security',
			color : 'light',
			title : 'EC2セキュリティ対策パッケージ',
			description : 'EC2インスタンスに対するセキュリティ対策及び監査対応を行います。Security Group、AWS Config、Security Hub、Cloud Watch',
			price : 50000,
			period : 10,
		},
		{
			name : 'SEC-3',
			type : 'security',
			color : 'light',
			title : 'EC2監視パッケージ',
			description : 'EC2インスタンスに対する監視の構築支援を行います。System Manager Agentによるパラメータ取得、Cloud Watchダッシュボードの作成も含みます。本オプション内では外部からの死活監視は含まれません。Cloud Watch、System Manager',
			price : 20000,
			period : 2,
		},
		*/
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={()=>setAlert([])} />
				<Row>
					<Col sm={12}>
						<h4>オプションの種類を選んでください。</h4>
						<p>
							お問合せ及び依頼をいただいた内容は、AWSが提供する生成AIサービスのBedrockに提供されます。<br/>
							Bedrockに提供されたデータは、学習データに用いられることはありません。
						</p>
					</Col>
				</Row>
				<Row style={{ marginTop: 20}}>
					{optionData.map((data, index) => (
						<Col md={3} style={{ marginTop: 20}} id={index}>
							<Card>
								<Card.Body>
									<Card.Text>
										<Button variant={data.color} onClick={()=>{setSelect(data);setConfirmation(true);}}>申し込む</Button> {data.name}
									</Card.Text>
									<Card.Text>
										{data.title}
									</Card.Text>
									<Card.Text>
										{data.description}
									</Card.Text>
									<Card.Text>
										{data.off ? <><Badge variant="danger">{data.off}%OFF</Badge><br /></> : ''}
										価格：
										{
											data.off ?
												(data.price - Math.floor(data.price * (data.off / 100)))?.toLocaleString()
											:
											data.price?.toLocaleString()
										} 円（税別） <br/>
										期間：約 {data.period} 日間
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
				<Modal show={confirmation} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
					<Form onSubmit={handleConfirmation}>
						<Modal.Header closeButton>
							<Modal.Title id="contained-modal-title-vcenter">
								お申し込みのご確認
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							ID：{select.name}<br/>
							内容：{select.title}<br/>
							詳細：{select.description}<br/>
							価格：{select.price?.toLocaleString()}  円<br />
							{
								select.off ?
									'特別価格：' + (select.price - (select.price * (select.off / 100))).toLocaleString() + ' 円 '
								:
									''
							}  
							{select.off ? <><Badge variant="danger">{select.off}%OFF</Badge><br /></> : ''}
							期間：約 {select.period} 日間
								<Form.Group controlId="formTechnicalCustomer">
								<Form.Label>対象の顧客番号：</Form.Label>
								<Form.Control as="select" name="customer_no" onChange={(e)=>setCustomerNo(e.target.value)} value={customerNo} required>
									{customerData.map((item, index) => {
											return(
												<option key={index} value={item.customer_no}>{item.customer_name}（{item.customer_no}）</option>
											);
										}
									)}
								</Form.Control>
							</Form.Group>
							<Form.Group controlId="formTechnicalAWS">
								<Form.Label>対象のAWSアカウント：</Form.Label>
								<Form.Control as="select" name="account_no" onChange={(e)=>setAccountNo(e.target.value)} value={accountNo} required>
									{awsData.map((item, index) => {
											return(
												<option key={index} value={item.account_no}>{item.account_name}（{item.account_no}）</option>
											);
										}
									)}
								</Form.Control>
							</Form.Group>
							<Form.Group controlId="formTechnicalUserName">
								<Form.Label>サポート：</Form.Label>
								<Form.Control type="text" name="user_name" value={supportName ? supportName : '契約しているサポートがないため、対象のAWSアカウントはお申し込み出来ません'} placeholder="サポート" readOnly={true} />
							</Form.Group>
							<Form.Group controlId="formTechnicalTextValue">
								<Form.Label>ご依頼内容：</Form.Label>
								<Form.Control as="textarea" name="text_value" rows={5} value={textValue} onChange={(e)=>setTextValue(e.target.value)} placeholder="ご依頼内容の詳細を記載ください" disabled={supportName ? false : true} required />
							</Form.Group>
							<Form.Group controlId="formBasicMailCC">
								<Form.Label>メールCC宛先</Form.Label>
								<Form.Control type="email" name="mail_cc" placeholder="メールCC宛先" value={mailCC}  onChange={(e)=>setMailCC(e.target.value)} disabled={supportName ? false : true} />
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button variant={'danger'} type="submit" disabled={supportName ? false : true}>次へ</Button>
							<Button onClick={handleClose}>閉じる</Button>
						</Modal.Footer>
					</Form>
				</Modal>
				<Modal show={agreement} onHide={handleBack} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
					<Form onSubmit={handleAgreement}>
						<Modal.Header closeButton>
							<Modal.Title id="contained-modal-title-vcenter">
								合意事項の確認
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
								<iframe 
									title="契約約款"
									src={`${document_url}#page=1&scrollbar=1&view=FitH&toolbar=0&statusbar=0&messages=0&navpanes=0`}
									style={{ width: '100%', height: '500px' }}
								></iframe>
							
								<h4>約款の内容をよく読み合意してください</h4>
								<p>
									ドキュメントは<a href={document_url} target="_blank" rel="noreferrer">こちら</a>よりダウンロードできます。<br/>
									合意する場合は、下記のボタンをクリックしてください。<br/>
								</p>
						</Modal.Body>
						<Modal.Footer>
							<Button variant={'danger'} type={handleAgreement} disabled={supportName ? false : true}>合意して申し込む</Button>
							<Button onClick={handleBack}>戻る</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</Container>
		);
	}
};

export default withRouter(Default);
