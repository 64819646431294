import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const AWSBilling = {};

AWSBilling.Query = async (account_no) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryAWSBilling, { account_no }));
		let items = ref.data.queryAWSBilling.items;
		while (ref.data.queryAWSBilling.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryAWSBilling, { account_no, nextToken: ref.data.queryAWSBilling.nextToken }));
			items = items.concat(ref.data.queryAWSBilling.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

AWSBilling.QueryDetails = async (account_no, date) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryAWSBillingDetails, { account_no, date }));
		let items = ref.data.queryAWSBillingDetails.items;
		while (ref.data.queryAWSBillingDetails.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryAWSBillingDetails, { account_no, date, nextToken: ref.data.queryAWSBillingDetails.nextToken }));
			items = items.concat(ref.data.queryAWSBillingDetails.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

export default AWSBilling;


