import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormButton } from '../../Objects/Form';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { ns53_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState({});
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.NS53.Get(ns53_no)).item;
			
			if(Object.keys(newData).length){
				setData(newData);
			}
			else{
				setNotfound(true);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>

				<Form name="form">

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'契約ID'} name={'ns53_no'} defaultValue={data.ns53_no} readOnly={true} />
					<FormButton label={'管理画面'} value={'ゾーン一覧'} onClick={()=>{window.location.href='/ns53/' + data.ns53_no + '/domains/'}} />
					<hr/>
					<FormText label={'請求アカウント'} name={'customer_no'} defaultValue={data.customer_no + '　' + data.customer_name}  readOnly={true} />
					<FormText label={'契約日'} name={'contract_date'} defaultValue={dateShapingDate(data.contract_date)} type={'date'} readOnly={true} />
					<FormText label={'プラン名'} name={'title'} defaultValue={data.title} readOnly={true} />
					<FormText label={'ゾーン数'} name={'domain_num'} defaultValue={data.domain_num} type={'number'} readOnly={true} />
					<FormText label={'レコード数'} name={'record_num'} defaultValue={data.record_num} type={'number'} readOnly={true} />
					<FormText label={'月額費用'} name={'price_notax'} defaultValue={data.price_notax.toLocaleString() + '円'} readOnly={true} />
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} defaultValue={dateShapingMonth(data.bill_start)} type={'month'} readOnly={true} />
					<FormText label={'課金停止月'} name={'bill_end'} defaultValue={dateShapingMonth(data.bill_end)} type={'month'} readOnly={true} />
					<hr/>
					<FormText label={'ステータス'} name={'status'} defaultValue={data.status} readOnly={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
