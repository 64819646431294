import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect, FormTextArea } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';
import NotFound from '../../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { ns53_no, domain_name, name, type } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [count, setCount] = useState(0);
	const [refresh, setRefresh] = useState([true]);
	const [notfound, setNotfound] = useState(false);
	const [customer_no, setCustomerNo] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.NS53.RecrodGet(name, type)).item;
			
			if(!Object.keys(newData).length){
				newData.name = domain_name;
				newData.ttl = 600;
			}
			setData(newData);
			
			const customer_no = ((await API.NS53.Get(ns53_no)).item.customer_no);
			
			if(customer_no){
				setCustomerNo(customer_no);
			}
			else{
				setNotfound(true);
			}
			
			//件数の取得
			const ref = await API.NS53.RecordGetAll(domain_name);
			setCount(ref.items ? ref.items.length : 0);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	const handleSubmit = async (event) => {
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		try {
		
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			//console.log(count);
			//console.log(data.domain_num);
			if(data.record_num <= count){
				throw new Error('登録できる上限数を超えています。');
			}

			if(ns53_no && domain_name && customer_no && data.name && data.type && data.content && data.ttl){
				if(data.type === 'MX'){
					if(data.content.indexOf(' ') === -1){
						throw new Error('MXレコードの場合はプライオリティの指定が必要です。');
					}
				}
				
				if(type){
					//更新
					const ref = await API.NS53.RecordUpdate({
						name: name,
						type: type,
						content: data.content,
						ttl: data.ttl,
						ns53_no: ns53_no,
						customer_no: customer_no,
						domain_name: domain_name,
					});
					//エラー確認
					if(ref.error){
						throw new Error('入力エラーがありました。' + ref.error);
					}
					
					throw new Error('更新しました。');
				}
				else{
					//新規追加
					const ref = await API.NS53.RecordCreate({
						name: data.name,
						type: data.type,
						content: data.content,
						ttl: data.ttl,
						ns53_no: ns53_no,
						customer_no: customer_no,
						domain_name: domain_name,
					});
					//エラー確認
					if(ref.error){
						throw new Error('入力エラーがありました。' + ref.error);
					}
					
					throw new Error('登録しました。');
				}
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		//const type = event.target.type;
		
		const newData = data;
		
		newData[name] = value;
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='登録しました。'){
			//window.location.href = '/ns53/' + ns53_no + '/' + domain_name + '/' +data.name + '/' + data.type + '/';
			window.location.href = '/ns53/' + ns53_no + '/' + domain_name + '/';
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Form onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>ゾーン登録</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'ドメイン名'} name={'domain_name'} defaultValue={domain_name} readOnly={true} />
					<hr/>
					<FormText label={'レコード名'} name={'name'} value={data.name}  onChange={handleOnChange} required={true} readOnly={type ? true : false} pattern="^.*{domain_name}$"/>
					<FormSelect label={'タイプ'} name={'type'} value={data.type} data={['A', 'CNAME', 'MX', 'TXT', 'NS', 'SOA', 'HINFO', 'PTR']} onChange={handleOnChange} required={true} disabled={type ? true : false} />
					<FormTextArea label={'値'} name={'content'} value={data.content}  onChange={handleOnChange} required={true} />
					<FormText label={'TTL'} name={'ttl'} value={data.ttl} type={'number'} onChange={handleOnChange} required={true} />


					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={(data.name === domain_name && (data.type === 'NS' || data.type === 'SOA')) ? true : false}>登録する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
