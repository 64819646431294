import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const User = {};

User.Get = async () =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getUserCurrent));
		const item = ref.data.getUserCurrent;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}



export default User;


