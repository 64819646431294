import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormButton } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { ssl_name } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState([]);
	const [showStatus, setShowStatus] = useState([false]);
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.SSL.Get(ssl_name)).item;
			
			if(Object.keys(newData).length){
				setData(newData);
			}
			else{
				setNotfound(true);
			}
			
			//表示開始
			setLoad(false);
			
		};

		const checkData = async () =>{
			const sleep = sec => new Promise(resolve => setTimeout(resolve, sec * 1000));
			while (true) {
				getData();
				//wait
				await sleep(10);
				//console.log(data.is_get_key);
			}
		};
		
		checkData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	const updateStatus = async (event) => {
	
		setShowStatus([false]);
		
		try {
			
			if(ssl_name){
			
				const newData ={
					ssl_name : ssl_name,
					status : '対象外',
				};
				
				//更新
				const ref = await API.SSL.Update(newData);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新停止を申請しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const handleStatusShow = () => {
		setShowStatus([true]);
	};
	const handleGetKey = async () => {
		
		try {
			
			if(ssl_name){
			
				const newData ={
					ssl_name : ssl_name,
					is_get_key : '証明書ファイルを準備しています',
				};
				
				//証明書ファイルを準備しています
				//証明書ファイルの準備に失敗しました
				//ダウンロードの準備が完了しました
				//ダウンロード期限が終了しました
				
				//更新
				const ref = await API.SSL.Update(newData);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('証明書ファイルを準備しています。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		handleReload();
	};
	
	const handleDownLoadKey = async () => {
		
		const sleep = sec => new Promise(resolve => setTimeout(resolve, sec * 1000));
		
		try {
			
			if(ssl_name && data.is_get_key === 'ダウンロードの準備が完了しました'){

				const file_name = ssl_name.replace('*', '_');
				
				//ストレージを定義
				API.Storage.configure({ AWSS3: { bucket: 'onocomm-system-ssl-download', region: 'ap-northeast-1', level: 'public' } });
				
				//ファイルを取得
				if((await API.Storage.list('')).filter(x => x.key === file_name + '.zip').length === 0){
					throw new Error('File does not exist or is being created.');
				}
				
				//ファイルを取得
				const file_url = await API.Storage.get(file_name + '.zip');
				
				//ダウンロード
				window.location.href = file_url;
				
				await sleep(10);
				
				await API.Storage.remove(file_name + '.zip');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//handleReload();
	};
	
	const handleClose = () => {
		setAlert([]);
		setShowStatus([false]);
		handleReload();
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalStatus data={data} show={showStatus}  onClick={updateStatus} onHide={handleClose} />

				<Form name="form">

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'コモン名'} name={'ssl_name'} defaultValue={data.ssl_name} readOnly={true} />
					<hr/>
					<FormText label={'請求アカウント'} name={'customer_no'} defaultValue={data.customer_no ? (data.customer_no + '　' + data.customer_name) : ''} readOnly={true} />
					<FormText label={'ステータス'} name={'status'} defaultValue={data.status} readOnly={true} />
					<FormText label={'種類'} name={'ssl_kbn'} defaultValue={data.ssl_kbn} readOnly={true} />
					<hr/>
					<FormText label={'更新費用'} name={'price_notax'} defaultValue={data.price_notax.toLocaleString() + '円'} readOnly={true} />
					<hr/>
					<FormText label={'有効期限'} name={'expiration'} defaultValue={dateShapingDate(data.expiration)} type={'date'} readOnly={true} />
					<FormText label={'契約年数'} name={'contract_years'} defaultValue={data.contract_years.toLocaleString() + '年'} readOnly={true} />
					<FormText label={'次回請求月'} name={'billing_date'} defaultValue={dateShapingMonth(data.billing_date)} type={'month'} readOnly={true} />
					<hr/>
					<FormText label={'ダブルドメイン'} name={'double_domain_type'} defaultValue={data.double_domain_type} readOnly={true} />
					<FormText label={'認証方法'} name={'approval_type'} defaultValue={data.approval_type} readOnly={true} />
					<FormText label={'認証メールアドレス'} name={'approval_mail'} defaultValue={data.approval_mail} type={'mail'} readOnly={true} style={{display: data.approval_mail ? '' : 'none'}}  />
					<FormText label={'認証ファイル'} name={'approval_file'} defaultValue={data.approval_file} readOnly={true} style={{display: data.approval_file ? '' : 'none'}}  />
					<FormText label={'認証テキスト'} name={'approval_text'} defaultValue={data.approval_text} readOnly={true} style={{display: data.approval_text ? '' : 'none'}}  />
					<FormText label={'引渡方法'} name={'target_type'} defaultValue={data.target_type} readOnly={true} />
					<FormText label={'設定先サーバーホスト名'} name={'target_server'} defaultValue={data.target_server} readOnly={true} style={{display: data.target_server ? '' : 'none'}}  />
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} defaultValue={dateShapingMonth(data.bill_start)} type={'month'} readOnly={true} />
					<FormText label={'課金停止月'} name={'bill_end'} defaultValue={dateShapingMonth(data.bill_end)} type={'month'} readOnly={true} />
					<hr/>
					<FormButton label={'証明書取得'} value={data.is_get_key ? data.is_get_key : '証明書の取得'} onClick={data.is_get_key === null ? handleGetKey : handleDownLoadKey} disabled={(data.is_get_key === null || data.is_get_key === 'ダウンロードの準備が完了しました') ? false : true} />
					<hr/>
					<FormButton label={'更新停止'} value={'更新の停止を申し込む'} onClick={handleStatusShow} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};

const ModalStatus = (props) => {

	const [show, setShow] = useState(false);
	const [flag, setFlag] = useState(true);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}

		const expiration = Number(props.data.expiration);
		const now_time = Math.floor((new Date()).getTime() / 1000);
		const exp_days = Math.floor((expiration - now_time) / (60 * 60 * 24));
		const status = props.data.status;
		
		if(exp_days > 65 && status === '更新対象'){
			setFlag(false);
		}

		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	return (
		<Modal show={show} onHide={props.onHide} size="lg">
			<Modal.Body>
				<Form>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        証明書の残り有効期間が65日を切る場合、ポータルサイトからは停止を申し込めません。
						        その際はお手数ですが、営業窓口までお問い合わせください。
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} disabled={flag} >次回更新を停止する</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(Default);
