import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const AWS = {};

AWS.Get = async (account_no) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getAWS, { account_no : account_no }));
		const item = ref.data.getAWS;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

AWS.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanAWS));
		let items = ref.data.scanAWS.items;
		while (ref.data.scanAWS.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanAWS, { nextToken: ref.data.scanAWS.nextToken }));
			items = items.concat(ref.data.scanAWS.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

AWS.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateAWS, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


AWS.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createAWS, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

AWS.Delete = async (account_no) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteAWS, { account_no: account_no }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

AWS.executeLambdaSupport = async (account_no, support_name) =>  {
	
	try{
	
		const ref = await API.graphql(graphqlOperation(GraphQL.executeLambdaAWSSupport,{ account_no, support_name }));
		const result = ref.data.executeLambdaAWSSupport.result ? ref.data.executeLambdaAWSSupport.result : false;
		const error  = ref.data.executeLambdaAWSSupport.error;
		//console.log(error);
		
		if(result === false){
			throw new Error(error);
		}
		return ({
			result : result,
			error: error,
		});
	}
	catch(err) {
		//console.log(err);
		return({
			result : false,
			error: err.message ? err.message : JSON.stringify(err),
		});
	}
}

export default AWS;
