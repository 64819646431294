//パスワードの変更を受け付ける

import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
//import { AuditLog } from '../../Objects/AuditLog';
import { ModalAlert } from '../../Objects/Modal';

const Default = (props) => {

	const { email } = useParams();
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [success, setSuccess] = useState(false);
	
	useEffect(() => {
		
		if(!email) {
			setAlert(['メールアドレスが確認出来ません。']);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	const handleSubmit = async (event) => {
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		//更新処理
		try{
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//Formから値取得
			const old_passwd = formObj.old_passwd.value
			const new_passwd = formObj.new_passwd.value

			//サインイン
			const result1 = await Auth.signIn(email, old_passwd);
			
			//debug
			console.log(result1);
			
			//requiredAttributesの取得
			const requiredAttributes = result1.challengeParam.requiredAttributes;
			
			//パスワードの変更
			const result2 = await Auth.completeNewPassword(result1, new_passwd, requiredAttributes);
			
			//debug
			console.log(result2);
			
			//成功
			setSuccess(true);
		}
		catch(err) {
			setAlert([err.message]);
		}
		
		//ロック解除
		setLock(false);

	};
	
	const handleClick = async (event) => {
		window.location.href='/';
	};
	
	return (
		<Container style={{ marginTop: 200, marginBottom: 200 }}>
			<ModalAlert data={alert} />

			<Modal show={success} onHide={handleClick}>
				<Modal.Body>
					パスワードを変更しました。<br/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClick}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>

			<Form onSubmit={handleSubmit}>
				<Row>
					<Col sm={{ span: 4, offset: 4 }}>
						<p className="text-center">
							<big>パスワードの変更</big><br/>
						</p>
					</Col>
				</Row>
				<Row>
					<Col sm={{ span: 4, offset: 4 }}>
						<p className="text-center">
							ログインを行うためにはパスワードを変更する必要があります。
						</p>
					</Col>
				</Row>
				<Row>
					<Col sm={{ span: 6, offset: 3 }} style={{ marginBottom: 50 }}>
						パスワードは8〜255文字で、大文字1文字、小文字1文字、数字1文字以上である必要があります。
					</Col>
				</Row>
				<Row>
					<Col sm={{ span: 4, offset: 4 }}>
						<Form.Group controlId="old_passwd">
						        <Form.Control name="old_passwd" type="password" placeholder="古いパスワード" required minLength="8" maxLength="255" disabled={lock} autoComplete="off" />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm={{ span: 4, offset: 4 }}>
						<Form.Group controlId="new_passwd">
						        <Form.Control name="new_passwd" type="password" placeholder="新しいパスワード" required minLength="8" maxLength="255" disabled={lock} autoComplete="off" />
						</Form.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: 10 }}>
					<Col sm={{ span: 4, offset: 4 }}>
					        <Button variant="dark" type="submit" block disabled={lock} >パスワード変更</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
}


export default withRouter(Default);
