import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Quotation = {};

Quotation.Get = async (quotation_no) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getQuotation, { quotation_no : quotation_no }));
		const item = ref.data.getQuotation;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Quotation.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanQuotation));
		let items = ref.data.scanQuotation.items;
		while (ref.data.scanQuotation.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanQuotation, { nextToken: ref.data.scanQuotation.nextToken }));
			items = items.concat(ref.data.scanQuotation.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Quotation.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateQuotation, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


Quotation.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createQuotation, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Quotation.Delete = async (quotation_no) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteQuotation, { quotation_no: quotation_no }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

export default Quotation;


