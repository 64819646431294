import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormButton } from '../../Objects/Form';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { server_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState({});
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.Server.Get(server_no)).item;
			
			if(server_no){
				setData(newData);
			}
			else{
				setNotfound(true);
			}


			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>

				<Form name="form">

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'ホスト名'} name={'server_name'} defaultValue={data.server_name} readOnly={true} />
					<hr/>
					<FormText label={'請求アカウント'} name={'customer_no'} defaultValue={data.customer_no + '　' + data.customer_name} readOnly={true} />
					<FormText label={'契約日'} name={'contract_date'} defaultValue={dateShapingDate(data.contract_date)} readOnly={true} type={'date'} />
					<FormText label={'タイプ'} name={'server_type'} defaultValue={data.server_type} readOnly={true} />
					<FormText label={'契約区分'} name={'payment_type'} defaultValue={data.payment_type} readOnly={true} />
					<FormText label={'サポートタイプ'} name={'support_type'} defaultValue={data.support_type} readOnly={true} style={{display: data.support_type ? '' : 'none'}} />
					<FormText label={'月額費用'} name={'total_price_notax'} defaultValue={data.total_price_notax.toLocaleString() + '円'} readOnly={true} />
					<hr/>
					<FormButton label={'オプション'} value={'オプション一覧'} onClick={()=>{window.location.href='/server/' + data.server_no + '/op/'}} />
					<hr/>
					<FormText label={'インスタンスタイプ'} name={'instance_type'} defaultValue={data.instance_type} readOnly={true} style={{display: data.instance_type ? '' : 'none'}} />
					<FormText label={'OS'} name={'os'} defaultValue={data.os} readOnly={true} style={{display: data.os ? '' : 'none'}} />
					<FormText label={'ディスク容量'} name={'disk_num'} defaultValue={data.disk_num ? data.disk_num.toLocaleString() + 'GB' : ''} readOnly={true} style={{display: data.disk_num ? '' : 'none'}} />
					<FormText label={'バックアップ容量'} name={'bakcup_num'} defaultValue={data.bakcup_num ? data.bakcup_num.toLocaleString() + 'GB' : ''} readOnly={true} style={{display: data.bakcup_num ? '' : 'none'}} />
					<FormText label={'トラフィック容量'} name={'transfer_num'} defaultValue={data.transfer_num ? data.transfer_num.toLocaleString() + 'GB' : ''} readOnly={true} style={{display: data.transfer_num ? '' : 'none'}} />
					<hr/>
					<div style={{display: (data.fqdn || data.ip) ? '' : 'none'}}>
						<FormText label={'FQDN'} name={'fqdn'} defaultValue={data.fqdn} readOnly={true} />
						<FormText label={'IPアドレス'} name={'ip'} defaultValue={data.ip} readOnly={true} />
						<hr/>
					</div>
					<div style={{display: data.admin_passwd ? '' : 'none'}}>
						<Row>
							<Form.Label column sm={3}>
								管理画面
							</Form.Label>
						</Row>
						<FormText label={'URL'} name={'admin_url'} defaultValue={data.admin_url} readOnly={true} />
						<FormText label={'ユーザ名'} name={'admin_user'} defaultValue={data.admin_user} readOnly={true} />
						<FormText label={'パスワード'} name={'admin_passwd'} defaultValue={data.admin_passwd} readOnly={true} type={'password'} onMouseOver={(event)=>{event.target.type='text';}} onMouseOut={(event)=>{event.target.type='password';}}/>
						<hr/>
					</div>
					<div style={{display: data.ftp_passwd ? '' : 'none'}}>
						<Row>
							<Form.Label column sm={3}>
								FTP
							</Form.Label>
						</Row>
						<FormText label={'ホスト名'} name={'ftp_host'} defaultValue={data.ftp_host} readOnly={true} />
						<FormText label={'ユーザ名'} name={'ftp_user'} defaultValue={data.ftp_user} readOnly={true} />
						<FormText label={'パスワード'} name={'ftp_passwd'} defaultValue={data.ftp_passwd} readOnly={true} type={'password'} onMouseOver={(event)=>{event.target.type='text';}} onMouseOut={(event)=>{event.target.type='password';}}/>
						<hr/>
					</div>
					<div style={{display: data.phpmyadmin_passwd ? '' : 'none'}}>
						<Row>
							<Form.Label column sm={3}>
								phpMyAdmin
							</Form.Label>
						</Row>
						<FormText label={'URL'} name={'phpmyadmin_url'} defaultValue={data.phpmyadmin_url} readOnly={true} />
						<FormText label={'ユーザ名'} name={'phpmyadmin_user'} defaultValue={data.phpmyadmin_user} readOnly={true} />
						<FormText label={'パスワード'} name={'phpmyadmin_passwd'} defaultValue={data.phpmyadmin_passwd} readOnly={true} type={'password'} onMouseOver={(event)=>{event.target.type='text';}} onMouseOut={(event)=>{event.target.type='password';}}/>
						<hr/>
					</div>
					<div style={{display: data.db_passwd ? '' : 'none'}}>
						<Row>
							<Form.Label column sm={3}>
								データベース関連
							</Form.Label>
						</Row>
						<FormText label={'ホスト名'} name={'db_host'} defaultValue={data.db_host} readOnly={true} />
						<FormText label={'ポート番号'} name={'db_port'} defaultValue={data.db_port} readOnly={true} />
						<FormText label={'ユーザ名'} name={'db_user'} defaultValue={data.db_user} readOnly={true} />
						<FormText label={'パスワード'} name={'db_passwd'} defaultValue={data.db_passwd} readOnly={true} type={'password'} onMouseOver={(event)=>{event.target.type='text';}} onMouseOut={(event)=>{event.target.type='password';}}/>
						<FormText label={'エンジン'} name={'db_engine'} defaultValue={data.db_engine} readOnly={true} />
						<hr/>
					</div>
					<FormText label={'課金開始月'} name={'bill_start'} defaultValue={dateShapingMonth(data.bill_start)} type={'month'} readOnly={true} />
					<FormText label={'課金停止月'} name={'bill_end'} defaultValue={dateShapingMonth(data.bill_end)} type={'month'} readOnly={true} />
					<hr/>
					<FormText label={'ステータス'} name={'status'} defaultValue={data.status} readOnly={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
