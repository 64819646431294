import { useState, useEffect  } from 'react';
import {
	Container,
	Row,
	Col,
	Image,
	Card,
	Form,
	Button,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';

const Default = () => {

	const [load, setLoad] = useState(true);
	const [alert, setAlert] = useState([]);
	const [select, setSelect] = useState(null);

	useEffect(() => {
		
		const getData = async () => {
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);


	const handleSubmit = async (support_name) => {

		setLoad(true);
		
		try {

			//エラー確認
			if(!select){
				throw new Error('サポートの種類を選択してください');
			}

			if(select === 'support'){
				window.location.href = '/support/contact/';
			}
			else if(select === 'option'){
				window.location.href = '/support/option/';
			}
			else{
				throw new Error('サポートの種類が不正です');
			}

		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLoad(false);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={()=>setAlert([])} />
				<Row>
					<Col sm={12}>
						<h4>サポートの種類を選んでください。</h4>
						<p>
							お問合せ及び依頼をいただいた内容は、AWSが提供する生成AIサービスのBedrockに提供されます。<br/>
							Bedrockに提供されたデータは、学習データに用いられることはありません。
						</p>
					</Col>
				</Row>
				<Row style={{ marginTop: 20}}>
					<Col md={6}>
						<Card onClick={()=>setSelect('support')}>
							<Card.Body>
								<Card.Text>
									<Form.Check type="radio" name="select1" id="radio-support" label="お問合せ" onClick={()=>setSelect('support')} checked={select === 'support'} />
								</Card.Text>
								<Card.Text>
									一般的及び技術的な質問についてのサポートを提供します。
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col md={6}>
						<Card onClick={()=>setSelect('option')}>
						<Card.Body>
								<Card.Text>
									<Form.Check type="radio" name="select1" id="radio-option" label="オプションの購入" onClick={()=>setSelect('option')} checked={select === 'option'} />
								</Card.Text>
								<Card.Text>
									構築支援などについてのオプションの購入を行います。
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row style={{ marginTop: 20}}>
					<Col sm={12}>
						<Button onClick={handleSubmit}>次へ</Button>
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
