import { useState, useEffect  } from 'react';
import { Storage } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Image,
	Card,
	Form,
	Button,
} from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../../Objects/Modal';
import API from '../../../@onocomm-system';
import { v4 as uuidv4 } from 'uuid';

const Default = () => {

	const { account_no_select } = useParams();
	const [load, setLoad] = useState(true);
	const [alert, setAlert] = useState([]);
	const [select, setSelect] = useState('technical');
	const [user, setUser] = useState({});
	const [customerData, setCustomerData] = useState([]);
	const [customerNo, setCustomerNo] = useState(null);
	const [defaultAWSData, setDefaultAWSData] = useState([]);
	const [awsData, setAWSData] = useState([]);
	const [accountNo, setAccountNo] = useState('');
	const [supportName, setSupportName] = useState('');
	const [techFlag, setTechFlag] = useState(false);
	const [case_id, setCaseId] = useState('');
	const [subjectValue, setSubjectValue] = useState('');
	const [textValue, setTextValue] = useState('');
	const [mailCCValue, setMailCCValue] = useState('');
	const [aiSkipFlag, setAISkipFlag] = useState(true);

	useEffect(() => {

		const getData = async () => {

			//ユーザ情報の取得
			const resultUser = await API.User.Get();
			const itemUser = resultUser.item;

			//AWS契約情報の取得
			const resultAWS = await API.AWS.GetAll();
			const itemAWS = resultAWS.items.filter(x=>x.status !== '無効');

			//契約情報からユニークの顧客情報を取得
			const customerGroup = Array.from(
				new Map(itemAWS.map(item => [item.customer_no, { ...item }])).values()
			).sort((a, b) => b?.contract_date - a?.contract_date);

			//顧客情報からAWSアカウントを取得
			let customer_no = '';
			if(account_no_select){
				customer_no = itemAWS.filter(x=>x.account_no === account_no_select)?.[0]?.customer_no ?? '';
			}
			else{
				customer_no = customerGroup?.[0]?.customer_no ?? '';
			}

			//表示開始
			setUser(itemUser);
			setCustomerNo(customer_no);
			setCustomerData(customerGroup);
			setDefaultAWSData(itemAWS);
			setLoad(false);
			
		};
		
		getData();
		
	},[account_no_select]);

	useEffect(() => {

		const AWSGroup= defaultAWSData.filter(x=>x.customer_no === customerNo);
		const AWSGroupEnableSupport = AWSGroup.filter(x=>x.aws_support?.filter((item) => item?.status === '有効').length > 0)?.[0] ?? {};
		const account_no = AWSGroupEnableSupport?.account_no ?? '';
		setAWSData(AWSGroup);
		setAccountNo(account_no_select ? account_no_select : account_no);

	},[account_no_select, customerNo, defaultAWSData]);

	useEffect(() => {
	
		const support_name = defaultAWSData.filter(x=> x.account_no === accountNo)?.[0]?.aws_support?.filter((item) => item?.status === '有効').sort((a, b) => b?.contract_date - a?.contract_date)?.[0]?.support_name ?? '';
		setSupportName(support_name);

	},[accountNo, defaultAWSData]);

	useEffect(() => {
	
		if(select === 'technical'){
			setTechFlag((accountNo && supportName) ? true : false);
		}
		else{
			setTechFlag(true);
		}

	},[accountNo, supportName, select]);

	useEffect(() => {
		if(case_id && accountNo){
			window.location.href = '/support/case/' + accountNo + '/' + case_id;
		}
	},[case_id, accountNo]);

	const handleSubmit = async (event) => {

		event.preventDefault();
		event.stopPropagation();

		setLoad(true);

		try{

			Storage.configure({ AWSS3: { bucket: 'onocomm-system-support-upload', region: 'ap-northeast-1', level: 'public' } });

			const user_name = user.user_name;
			const type = select;
			const account_no = accountNo;
			const customer_no = customerNo;
			const subject = subjectValue;
			const text_value = textValue;
			const mail_cc = mailCCValue;
			const file1Object = event.target?.file1?.files?.[0] ?? null;
			const file2Object = event.target?.file2?.files?.[0] ?? null;
			const file3Object = event.target?.file3?.files?.[0] ?? null;
			const ai_skip_flag = aiSkipFlag ? false : true;

			//console.log(file1Object, file2Object, file3Object);

			if(type === 'technical' && !techFlag){
				throw new Error('技術的なお問い合わせは契約しているサポートがないため、お問い合わせ出来ません');
			}
			
			if(!text_value){
				throw new Error('お問い合わせ内容が入力されていません');
			}

			if(!customer_no){
				throw new Error('お客様番号が取得できませんでした');
			}

			if(!user_name){
				throw new Error('ユーザー名が取得できませんでした');
			}

			let file1_original_name = null;
			let file1_name = null;
			if(file1Object && type === 'technical'){
				const file1Extension = file1Object.name.split('.').pop();
				const file1Size = file1Object.size;
				if(file1Size > 1024 * 1024 * 5){
					throw new Error('ファイルサイズが5MBを超えています');
				}
				file1_original_name = file1Object.name;
				file1_name = uuidv4() + '.' + file1Extension;
				await Storage.put(file1_name, file1Object);
			}
			let file2_original_name = null;
			let file2_name = null;
			if(file2Object && type === 'technical'){
				const file2Extension = file2Object.name.split('.').pop();
				const file2Size = file2Object.size;
				if(file2Size > 1024 * 1024 * 5){
					throw new Error('ファイルサイズが5MBを超えています');
				}
				file2_original_name = file2Object.name;
				file2_name = uuidv4() + '.' + file2Extension;
				await Storage.put(file2_name, file2Object);
			}
			let file3_original_name = null;
			let file3_name = null;
			if(file3Object && type === 'technical'){
				const file3Extension = file3Object.name.split('.').pop();
				const file3Size = file3Object.size;
				if(file3Size > 1024 * 1024 * 5){
					throw new Error('ファイルサイズが5MBを超えています');
				}
				file3_original_name = file3Object.name;
				file3_name = uuidv4() + '.' + file3Extension;
				await Storage.put(file3_name, file3Object);
			}

			const params = {
				user_name,
				account_no,
				customer_no,
				type,
				subject,
				text_value,
				file1_original_name,
				file1_name,
				file2_original_name,
				file2_name,
				file3_original_name,
				file3_name,
				mail_cc,
				ai_skip_flag,
			};

			const result = await API.AWSSupport.Create(params);

			if(result.error){
				throw new Error(result.error);
			}

			const case_id = result?.ref?.data?.createAWSSupport?.case_id;

			if(!case_id){
				throw new Error('ケースIDが取得できませんでした');
			}

			setCaseId(case_id);

		}
		catch(err){
			setAlert([err.message]);
			setLoad(false);
		}

	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={()=>setAlert([])} />
				<Row>
					<Col sm={12}>
						<h4>お問合せの種類を選んでください。</h4>
						<p>
							お問合せ及び依頼をいただいた内容は、AWSが提供する生成AIサービスのBedrockに提供されます。<br/>
							Bedrockに提供されたデータは、学習データに用いられることはありません。
						</p>
					</Col>
				</Row>
				<Row style={{ marginTop: 20}}>
					<Col md={6}>
						<Card onClick={()=>setSelect('basic')}>
							<Card.Body>
								<Card.Text>
									<Form.Check type="radio" name="select1" id="radio-support" label="一般的なお問い合わせ" onClick={()=>setSelect('basic')} checked={select === 'basic'} />
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col md={6}>
						<Card onClick={()=>setSelect('technical')}>
							<Card.Body>
								<Card.Text>
									<Form.Check type="radio" name="select1" id="radio-option" label="技術的なお問い合わせ" onClick={()=>setSelect('technical')} checked={select === 'technical'} />
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row style={{ marginTop: 20}}>
					<Col sm={12}>

						<Form onSubmit={handleSubmit}>
							<Form.Group controlId="formTechnicalCustomer">
								<Form.Label>対象のお客様番号</Form.Label>
								<Form.Control as="select" name="customer_no" onChange={(e)=>setCustomerNo(e.target.value)} value={customerNo} required>
									{customerData.map((item, index) => {
											return(
												<option key={index} value={item.customer_no}>{item.customer_name}（{item.customer_no}）</option>
											);
										}
									)}
								</Form.Control>
							</Form.Group>
							<Form.Group controlId="formTechnicalAccount">
								<Form.Label>対象のAWSアカウント</Form.Label>
								<Form.Control as="select" name="account_no" onChange={(e)=>setAccountNo(e.target.value)} value={accountNo} required>
									{awsData.map((item, index) => {
											return(
												<option key={index} value={item.account_no}>{item.account_name}（{item.account_no}）</option>
											);
										}
									)}
									<option style={{ display: select === 'technical' ? 'none' : 'block' }} value={'etc'}>AWS以外のお問い合わせ</option>
								</Form.Control>
							</Form.Group>
							<Form.Group controlId="formTechnicalSupport" style={{ display: select === 'technical' ? 'block' : 'none' }}>
								<Form.Label>サポート</Form.Label>
								<Form.Control type="text" name="user_name" value={supportName ? supportName : '契約しているサポートがないため、対象のAWSアカウントはお問い合わせ出来ません'} placeholder="サポート" readOnly={true} />
							</Form.Group>
							<Form.Group controlId="formTechnicalTitle">
								<Form.Label>題名</Form.Label>
								<Form.Control type="text" name="subject" placeholder="題名" disabled={!techFlag} value={subjectValue} onChange={(e)=>setSubjectValue(e.target.value)} required />
							</Form.Group>
							<Form.Group controlId="formTechnicalValue">
								<Form.Label>お問い合わせ内容（パスワードや秘密情報、個人情報の入力は行わないようにしてください）</Form.Label>
								<Form.Control as="textarea" name="text_value" rows={10} placeholder="お問い合わせ内容" value={textValue} onChange={(e)=>setTextValue(e.target.value)} disabled={!techFlag} required />
							</Form.Group>
							<Form.Group controlId="formTechnicalFile1" style={{ display: select === 'technical' ? 'block' : 'none' }}>
								<Form.File name="file1" id="formTechnicalFile1" label="ファイル1を添付" disabled={!techFlag || select === 'basic'} />
							</Form.Group>
							<Form.Group controlId="formTechnicalFile2" style={{ display: select === 'technical' ? 'block' : 'none' }}>
								<Form.File name="file2" id="formTechnicalFile2" label="ファイル2を添付" disabled={!techFlag || select === 'basic'} />
							</Form.Group>
							<Form.Group controlId="formTechnicalFile3" style={{ display: select === 'technical' ? 'block' : 'none' }}>
								<Form.File name="file3" id="formTechnicalFile3" label="ファイル3を添付" disabled={!techFlag || select === 'basic'} />
							</Form.Group>
							<Form.Group controlId="formTechnicalName">
								<Form.Label>お問い合わせされる方</Form.Label>
								<Form.Control type="text" name="user_name" defaultValue={user.user_name} placeholder="お名前" readOnly={true} />
							</Form.Group>
							<Form.Group controlId="formMailCC">
								<Form.Label>メールCC宛先</Form.Label>
								<Form.Control type="email" name="mail_cc" placeholder="メールCC宛先"  value={mailCCValue} onChange={(e)=>setMailCCValue(e.target.value)} disabled={!techFlag} />
							</Form.Group>
							<Form.Group controlId="formAISkipFlag">
								<Form.Label>AI返答を有効</Form.Label>
								<Form.Check type="checkbox" name="ai_skip_flag" checked={aiSkipFlag} onChange={(e)=>setAISkipFlag(aiSkipFlag ? false : true)} label={'AIによる返答を有効にする'} />
								<small>
									有効にした場合、人間のスタッフが返答をするまでの間、有効なヒントや案内を素早く得られる可能性があります。<br/>
									また、その場合お問合せ及び依頼をいただいた内容は、AWSが提供する生成AIサービスのBedrockに提供されます。<br/>
									Bedrockに提供されたデータは、学習データに用いられることはありません。<br/>
								</small>
							</Form.Group>

							<Button variant="primary" type="submit" disabled={!techFlag}>ケースをオープン</Button>
						</Form>
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
