import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Mail = {};

Mail.Get = async (domain_name) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getMail, { domain_name : domain_name }));
		const item = ref.data.getMail;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Mail.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanMail));
		let items = ref.data.scanMail.items;
		while (ref.data.scanMail.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanMail, { nextToken: ref.data.scanMail.nextToken }));
			items = items.concat(ref.data.scanMail.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Mail.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateMail, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


Mail.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createMail, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Mail.Delete = async (domain_name) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteMail, { domain_name: domain_name }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

export default Mail;


