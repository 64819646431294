import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const SSL = {};

SSL.Get = async (ssl_name) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getSSL, { ssl_name : ssl_name }));
		const item = ref.data.getSSL;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

SSL.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanSSL));
		let items = ref.data.scanSSL.items;
		while (ref.data.scanSSL.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanSSL, { nextToken: ref.data.scanSSL.nextToken }));
			items = items.concat(ref.data.scanSSL.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

SSL.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateSSL, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

SSL.ApplicationGet = async (request_id) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getSSLApplication, { request_id : request_id }));
		const item = ref.data.getSSLApplication;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

SSL.ApplicationGetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanSSLApplication));
		let items = ref.data.scanSSLApplication.items;
		while (ref.data.scanSSLApplication.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanSSLApplication, { nextToken: ref.data.scanSSLApplication.nextToken }));
			items = items.concat(ref.data.scanSSLApplication.items);
		}
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

SSL.ApplicationCreate = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createSSLApplication, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

SSL.ApplicationUpdate = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateSSLApplication, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default SSL;


