import Amplify, { Storage } from 'aws-amplify';
import AwsExports from './include/AwsExports';
import Customer from './API/Customer';
import Domain from './API/Domain';
import SSL from './API/SSL';
import Quotation from './API/Quotation';
import Order from './API/Order';
import Invoice from './API/Invoice';
import Bill from './API/Bill';
import NS53 from './API/NS53';
import AWS from './API/AWS';
import AWSBilling from './API/AWSBilling';
import AWSSupport from './API/AWSSupport';
import Server from './API/Server';
import Mail from './API/Mail';
import MailWhitelist from './API/MailWhitelist';
import User from './API/User';

Amplify.configure(AwsExports);


const API = {
	Customer  : Customer,
	Domain    : Domain,
	SSL       : SSL,
	Quotation : Quotation,
	Order     : Order,
	Invoice   : Invoice,
	Bill      : Bill,
	NS53      : NS53,
	AWS       : AWS,
	AWSBilling : AWSBilling,
	AWSSupport : AWSSupport,
	Server    : Server,
	Mail      : Mail,
	MailWhitelist : MailWhitelist,
	User      : User,
	Storage   : Storage
};


export default API;
