
const AwsExports = {
    Auth: {
        identityPoolId: 'ap-northeast-1:1f786bed-1a5a-4db3-8130-1d0dc1995a9b',
        userPoolId: 'ap-northeast-1_HHke70QHh',
        userPoolWebClientId: '5pfpt13i7476qe03mbe5c0tscd',
        region: 'ap-northeast-1',
    },
    API: {
        //'aws_appsync_graphqlEndpoint': 'https://gcvynrdp7rhyjoyumkhgfb6mgi.appsync-api.ap-northeast-1.amazonaws.com/graphql',
        'aws_appsync_graphqlEndpoint': 'https://api-portal.onocomm.jp/graphql',
        'aws_appsync_region': 'ap-northeast-1',
        'aws_appsync_authenticationType': 'AWS_IAM',
    },
};

export default AwsExports;
