import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { customer_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [refresh, setRefresh] = useState([true]);
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.Customer.Get(customer_no)).item;
			
			if(Object.keys(newData).length){
				setData(newData);
			}
			else{
				setNotfound(true);
			}
			
			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleCopy = (params) => {
	
		const copy = (param) => {
			
			try{
				const newData = data;
				const src = param.src;
				const dst = param.dst;
				//document.form[dst].value = document.form[src].value;
				newData[dst] = newData[src];
				setData(newData);
			}
			catch(err) {
				console.log(err);
			}
		};
	
		for(const param of params){
			copy(param);
		}
		setRefresh([true]);
	};

	const handleSubmit = async (event) => {
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
		
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(customer_no){
			
				const newData ={
					customer_no: customer_no,
					contractor_name: data.contractor_name,
					contractor_name_furi: data.contractor_name_furi,
					contractor_tel: data.contractor_tel,
					contractor_zip: data.contractor_zip,
					contractor_addr_1: data.contractor_addr_1,
					contractor_addr_2: data.contractor_addr_2,
					contractor_addr_3: data.contractor_addr_3,
					contractor_addr_en_1: data.contractor_addr_en_1,
					contractor_addr_en_2: data.contractor_addr_en_2,
					contractor_addr_en_3: data.contractor_addr_en_3,
					person_section: data.person_section,
					person_name: data.person_name,
					person_name_furi: data.person_name_furi,
					person_tel: data.person_tel,
					person_fax: data.person_fax,
					person_mail: data.person_mail,
					tech_section: data.tech_section,
					tech_name: data.tech_name,
					tech_name_furi: data.tech_name_furi,
					tech_tel: data.tech_tel,
					tech_fax: data.tech_fax,
					tech_mail: data.tech_mail,
					tech_emer_tel: data.tech_emer_tel,
					invoice_section: data.invoice_section,
					invoice_name: data.invoice_name,
					invoice_name_furi: data.invoice_name_furi,
					invoice_zip: data.invoice_zip,
					invoice_addr_1: data.invoice_addr_1,
					invoice_addr_2: data.invoice_addr_2,
					invoice_addr_3: data.invoice_addr_3,
					invoice_person_section: data.invoice_person_section,
					invoice_person_name: data.invoice_person_name,
					invoice_person_name_furi: data.invoice_person_name_furi,
					invoice_person_tel: data.invoice_person_tel,
					invoice_person_fax: data.invoice_person_fax,
					invoice_person_mail: data.invoice_person_mail,
					invoice_mail: data.invoice_mail,
					invoice_mail_cc_1: data.invoice_mail_cc_1,
					invoice_mail_cc_2: data.invoice_mail_cc_2,
					invoice_mail_cc_3: data.invoice_mail_cc_3,
					invoice_mail_cc_4: data.invoice_mail_cc_4,
					invoice_mail_cc_5: data.invoice_mail_cc_5,
				};
				
				//更新
				const ref = await API.Customer.Update(newData);

				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
		
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = data;
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.details){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.details = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		setAlert([]);
	};
	
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Form onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'登録日時'} name={'date_add'} defaultValue={dateShapingDate(data.date_add)} readOnly={true} type={'date'} />
					<FormText label={'アカウント番号'} name={'customer_no'} defaultValue={customer_no} readOnly={true} />
					<FormText label={'請求書送付方法'} name={'invoice_mail_flag'} defaultValue={data.invoice_mail_flag} readOnly={true} />
					<FormText label={'請求方法'} name={'invoice_pay_kbn'} defaultValue={data.invoice_pay_kbn} readOnly={true} />
					<hr/>
					<FormText label={'登録区分'} name={'contract_kbn'} defaultValue={data.contract_kbn} readOnly={true} />
					<FormText label={'お客様名'} name={'customer_name'} defaultValue={data.customer_name} readOnly={true} />
					<FormText label={'英語名'} name={'customer_name_en'} defaultValue={data.customer_name_en} readOnly={true} />
					<hr/>
					<FormText label={'代表者様名'} name={'contractor_name'} value={data.contractor_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'contractor_name_furi'} value={data.contractor_name_furi} onChange={handleOnChange} />
					<hr/>
					<FormText label={'電話番号'} name={'contractor_tel'} value={data.contractor_tel} type={'tel'} onChange={handleOnChange} required={true} />
					<hr/>
					<FormText label={'郵便番号'} name={'contractor_zip'} value={data.contractor_zip} onChange={handleOnChange} required={true} />
					<FormText label={'住所1'} name={'contractor_addr_1'} value={data.contractor_addr_1} onChange={handleOnChange} required={true} />
					<FormText label={'住所2'} name={'contractor_addr_2'} value={data.contractor_addr_2} onChange={handleOnChange} required={true} />
					<FormText label={'住所3'} name={'contractor_addr_3'} value={data.contractor_addr_3} onChange={handleOnChange} />
					<FormText label={'住所1（英語）'} name={'contractor_addr_en_1'} value={data.contractor_addr_en_1} onChange={handleOnChange} />
					<FormText label={'住所2（英語）'} name={'contractor_addr_en_2'} value={data.contractor_addr_en_2} onChange={handleOnChange} />
					<FormText label={'住所3（英語）'} name={'contractor_addr_en_3'} value={data.contractor_addr_en_3} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>契約担当者情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'contractor_name', dst:'person_name'},
																{src:'contractor_name_furi', dst:'person_name_furi'},
																{src:'contractor_tel', dst:'person_tel'},
															])} >代表情報 -> 契約担当者 Copy</Button>
						</Col>
					</Row>
					<FormText label={'部署名'} name={'person_section'} value={data.person_section} onChange={handleOnChange} />
					<FormText label={'担当者様名'} name={'person_name'} value={data.person_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'person_name_furi'} value={data.person_name_furi} onChange={handleOnChange} />
					<FormText label={'電話番号'} name={'person_tel'} value={data.person_tel} type={'tel'} onChange={handleOnChange} required={true} />
					<FormText label={'FAX'} name={'person_fax'} value={data.person_fax} type={'tel'} onChange={handleOnChange} />
					<FormText label={'Eメール'} name={'person_mail'} value={data.person_mail} type={'email'} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>技術担当者情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'person_section', dst:'tech_section'},
																{src:'person_name', dst:'tech_name'},
																{src:'person_name_furi', dst:'tech_name_furi'},
																{src:'person_tel', dst:'tech_tel'},
																{src:'person_fax', dst:'tech_fax'},
																{src:'person_mail', dst:'tech_mail'},
															])} >契約担当者 -> 技術担当者 Copy</Button>
						</Col>
					</Row>
					<FormText label={'部署名'} name={'tech_section'} value={data.tech_section} />
					<FormText label={'担当者様名'} name={'tech_name'} value={data.tech_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'tech_name_furi'} value={data.tech_name_furi} onChange={handleOnChange} />
					<FormText label={'電話番号'} name={'tech_tel'} value={data.tech_tel} type={'tel'} onChange={handleOnChange} required={true} />
					<FormText label={'FAX'} name={'tech_fax'} value={data.tech_fax} type={'tel'} onChange={handleOnChange} />
					<FormText label={'Eメール'} name={'tech_mail'} value={data.tech_mail} type={'email'} onChange={handleOnChange} />
					<FormText label={'緊急連絡先'} name={'tech_emer_tel'} value={data.tech_emer_tel} type={'tel'} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>経理担当者情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'person_section', dst:'invoice_person_section'},
																{src:'person_name', dst:'invoice_person_name'},
																{src:'person_name_furi', dst:'invoice_person_name_furi'},
																{src:'person_tel', dst:'invoice_person_tel'},
																{src:'person_fax', dst:'invoice_person_fax'},
																{src:'person_mail', dst:'invoice_person_mail'},
															])} >契約担当者 -> 経理担当者 Copy</Button>
						</Col>
					</Row>
					<FormText label={'部署名'} name={'invoice_person_section'} value={data.invoice_person_name} />
					<FormText label={'担当者様名'} name={'invoice_person_name'} value={data.invoice_person_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'invoice_person_name_furi'} value={data.invoice_person_name_furi} onChange={handleOnChange} />
					<FormText label={'電話番号'} name={'invoice_person_tel'} value={data.invoice_person_tel} type={'tel'} onChange={handleOnChange} required={true} />
					<FormText label={'FAX'} name={'invoice_person_fax'} value={data.invoice_person_fax} type={'tel'} onChange={handleOnChange} />
					<FormText label={'Eメール'} name={'invoice_person_mail'} value={data.invoice_person_mail} type={'email'} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>請求書宛名情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'invoice_person_section', dst:'invoice_section'},
																{src:'invoice_person_name', dst:'invoice_name'},
																{src:'invoice_person_name_furi', dst:'invoice_name_furi'},
															])} >経理担当者 -> 請求書宛名 Copy</Button>
							　
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'contractor_zip', dst:'invoice_zip'},
																{src:'contractor_addr_1', dst:'invoice_addr_1'},
																{src:'contractor_addr_2', dst:'invoice_addr_2'},
																{src:'contractor_addr_3', dst:'invoice_addr_3'},
															])} >契約者住所 -> 請求書宛名 Copy</Button>
						</Col>
					</Row>
					<FormText label={'部署名'} name={'invoice_section'} value={data.invoice_section} />
					<FormText label={'担当者様名'} name={'invoice_name'} value={data.invoice_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'invoice_name_furi'} value={data.invoice_name_furi} onChange={handleOnChange} />
					<FormText label={'郵便番号'} name={'invoice_zip'} value={data.invoice_zip} onChange={handleOnChange} required={true} />
					<FormText label={'住所1'} name={'invoice_addr_1'} value={data.invoice_addr_1} onChange={handleOnChange} required={true} />
					<FormText label={'住所2'} name={'invoice_addr_2'} value={data.invoice_addr_2} onChange={handleOnChange} required={true} />
					<FormText label={'住所3'} name={'invoice_addr_3'} value={data.invoice_addr_3} onChange={handleOnChange} />
					<hr/>
					<p>請求書送付先情報</p>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>請求書宛名情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'invoice_person_mail', dst:'invoice_mail'},
															])} >経理担当者 -> 送付先Eメール Copy</Button>
						</Col>
					</Row>
					<FormText label={'送付先Eメール'} name={'invoice_mail'} value={data.invoice_mail} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_1）'} name={'invoice_mail_cc_1'} value={data.invoice_mail_cc_1} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_2）'} name={'invoice_mail_cc_2'} value={data.invoice_mail_cc_2} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_3）'} name={'invoice_mail_cc_3'} value={data.invoice_mail_cc_3} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_4）'} name={'invoice_mail_cc_4'} value={data.invoice_mail_cc_4} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_5）'} name={'invoice_mail_cc_5'} value={data.invoice_mail_cc_5} type={'email'} onChange={handleOnChange} />
					<hr/>
					<FormText label={'摘要'} name={'invoice_text'} value={data.invoice_text} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >更新する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
				
			</Container>
		);
	}
};

export default withRouter(Default);
