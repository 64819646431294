import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	ProgressBar,
	Table,
} from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';

const Default = () => {

	const { account_no } = useParams();
	const [load, setLoad] = useState(true);
	const [data, setData] = useState([]);
	const [dateList, setDateList] = useState([]);
	const [selectYear, setSelectYear] = useState(new Date().getFullYear());
	const [notfound, setNotfound] = useState(false);

	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.AWS.Get(account_no)).item;
			
			if(!Object.keys(newData).length){
				setNotfound(true);
				setLoad(false);
				return;
			}

			//AWSサポート情報
			const aws_support = newData?.aws_support ?? [];

			//データ初期化
			const buildData = [];

			// 月ごとにデータを取得
			for (let month = 12; month >= 1; month--) {

				const thisData = {
					month: month,
					working: 0,
					completed: 0,
					limit: 0,
				};

				// 月初と翌月1日を取得
				const startDate = new Date(selectYear, month, 1);      // 各月の1日
				const endDate = new Date(selectYear, month + 1, 1);    // 翌月の1日
			
				// UNIXタイムスタンプに変換
				const startUnixTime = Math.floor(startDate.getTime() / 1000);
				const endUnixTime = Math.floor(endDate.getTime() / 1000);

				// 各月の有効な契約を判定
				const activeSupport = aws_support.find(support => {
					// サポートが「有効」で、期間内に開始し、終了していないか、または終了日が範囲内にある
					return support.status === "有効" &&
						support.bill_start <= endUnixTime &&
						(!support.bill_end || support.bill_end >= startUnixTime);
				});

				// 月の制限数を取得
				thisData.limit = activeSupport ? (
					activeSupport.support_name === 'マネージド' ? 7 :
					activeSupport.support_name === 'アドバンスド' ? 2 : 0
				) : 0;

				//構築支援対応の工数を取得
				const resultSupport = await API.AWSSupport.GetAll(account_no, startUnixTime, endUnixTime);
				const itemSupport = resultSupport.items;
				const targetSupport = itemSupport.filter(x=> x.build_period && (x.build_status === '作業完了' || x.build_status === '作業中'));

				// 作業中の工数を計算
				for(const item of targetSupport){
					if(item.build_status === '作業中'){
						thisData.working += Number(item.build_period ?? 0);
					}
					else if(item.build_status === '作業完了'){
						thisData.completed += Number(item.build_period ?? 0);
					}
				}

				// データを追加
				buildData.push(thisData);
			}

			//データセット
			setData(buildData);

			//過去10年間のリスト
			const currentYear = new Date().getFullYear();
		    const dateList = Array.from({ length: 10 }, (_, i) => currentYear - i); // 過去10年間のリスト

			//データセット
			setDateList(dateList);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[account_no, selectYear]);
		
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<Row>
					<Col sm={12}>
						<h4>AWSアカウントの構築支援情報</h4>
					</Col>
					<Col sm={12}>
						<Form>
							<Row style={{ marginTop: 20 }}>
								<Col sm={2}>
									<Form.Group controlId="formTechnicalEmail">
										<Form.Control as="select" name="selectYear" onChange={(e)=>{setSelectYear(e.target.value)}} value={selectYear}>
											{dateList.map((year, index) => {
													return(
														<option key={index} value={year}>{year}年</option>
													);
												}
											)}
										</Form.Control>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
					<Table striped bordered hover>
					<thead>
						<tr>
						<th>月</th>
						<th>構築作業</th>
						</tr>
					</thead>
					<tbody>
					{data.map((item, index) => {
						// それぞれのパーセンテージを計算
						const workingPercentage = (item.working / item.limit) * 100;
						const completedPercentage = (item.completed / item.limit) * 100;
						const remainingPercentage = item.limit ? (100 - (workingPercentage + completedPercentage)) : 0;
						return (
							<tr key={index}>
								<td width={'20%'}>{selectYear}年{item.month}月</td>
								<td>
									<ProgressBar>
										<ProgressBar striped variant="success" now={completedPercentage} key={1} label={completedPercentage ? `完了 ${item.completed}日` : ''} />
										<ProgressBar striped variant="warning" now={workingPercentage } key={2} label={workingPercentage ? `作業中 ${item.working}日` : ''} />
										<ProgressBar  variant="info" now={remainingPercentage} label={remainingPercentage ? `契約枠 ${item.limit}日` : ''} key={3} />
									</ProgressBar>
								</td>
							</tr>
						);
					})}
					</tbody>
					</Table>
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
