import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormButton } from '../../Objects/Form';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { bill_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState({});
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.Bill.Get(bill_no)).item;
			
			if(Object.keys(newData).length){
				setData(newData);
			}
			else{
				setNotfound(true);
			}
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>

				<Form name="form">

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'件名'} name={'title'} defaultValue={data.title} readOnly={true} />
					<hr/>
					<FormText label={'請求アカウント'} name={'customer_no'} defaultValue={data.customer_no + '　' + data.customer_name} readOnly={true} />
					<FormText label={'契約日'} name={'contract_date'} defaultValue={dateShapingDate(data.contract_date)} readOnly={true} type={'date'} />
					<FormText label={'月額費用'} name={'total_price_notax'} defaultValue={data.total_price_notax ? data.total_price_notax.toLocaleString() + '円' : ''} readOnly={true} />
					<hr/>
					<FormButton label={'明細'} value={'明細一覧'} onClick={()=>{window.location.href='/bill/' + data.bill_no + '/details/'}} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							請求月
						</Form.Label>
						<Col sm={9}>
							<input type="checkbox" name="bill_month01" checked={data.bill_month01 ? true : false} disabled={true} /> 1月　
							<input type="checkbox" name="bill_month02" checked={data.bill_month02 ? true : false} disabled={true} /> 2月　
							<input type="checkbox" name="bill_month03" checked={data.bill_month03 ? true : false} disabled={true} /> 3月　
							<input type="checkbox" name="bill_month04" checked={data.bill_month04 ? true : false} disabled={true} /> 4月　
							<input type="checkbox" name="bill_month05" checked={data.bill_month05 ? true : false} disabled={true} /> 5月　
							<input type="checkbox" name="bill_month06" checked={data.bill_month06 ? true : false} disabled={true} /> 6月　
							<input type="checkbox" name="bill_month07" checked={data.bill_month07 ? true : false} disabled={true} /> 7月　
							<input type="checkbox" name="bill_month08" checked={data.bill_month08 ? true : false} disabled={true} /> 8月　
							<input type="checkbox" name="bill_month09" checked={data.bill_month09 ? true : false} disabled={true} /> 9月　
							<input type="checkbox" name="bill_month10" checked={data.bill_month10 ? true : false} disabled={true} /> 10月　
							<input type="checkbox" name="bill_month11" checked={data.bill_month11 ? true : false} disabled={true} /> 11月　
							<input type="checkbox" name="bill_month12" checked={data.bill_month12 ? true : false} disabled={true} /> 12月
						</Col>
					</Row>
					<FormText label={'課金開始月'} name={'bill_start'} defaultValue={dateShapingMonth(data.bill_start)} type={'month'} readOnly={true} />
					<FormText label={'課金停止月'} name={'bill_end'} defaultValue={dateShapingMonth(data.bill_end)} type={'month'} readOnly={true} />
					<hr/>
					<FormText label={'ステータス'} name={'status'} defaultValue={data.status} readOnly={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
