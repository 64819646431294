//ログイン処理を行う

import { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
//import { AuditLog } from '../../Objects/AuditLog';
import { ModalAlert } from '../../Objects/Modal';

const Default = (props) => {

	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [success, setSuccess] = useState(false);
	const [email, setEmail] = useState(false);

	const handleSubmit = async (event) => {

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try{
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//Formから直接取得する更新項目
			const email   = formObj.email.value;
			const passwd  = formObj.passwd.value;
			
			//記録
			setEmail(email);

			//サインイン
			const result = await Auth.signIn(email, passwd);
			
			//鑑査ログ
			//await AuditLog('system', 'login', null, null, 'ログインしました。');
			
			//パスワードの変更要求があった場合
			if(result.challengeName === 'NEW_PASSWORD_REQUIRED'){
				window.location.href='/signin-change-password/' + email + '/';
			}
			else{
				//移動
				window.location.href='/';
			}
		}
		catch(err) {
			if(err.code === 'PasswordResetRequiredException'){
				//パスワードのリセットがあった場合
				setSuccess(true);
			}
			else if(err.code === 'NotAuthorizedException'){
				//パスワードが正しくない場合
				setAlert(['E-mail address or password is incorrect.']);
			}
			else{
				//その他エラー
				setAlert([err.message]);
			}
		}
		
		//ロック解除
		setLock(false);
		
	};
	
	const handleClick = async (event) => {
		window.location.href='/forgot-password-verify' + (email ? '/' + email : '') + '/';
	};
	
	return (
		<Container style={{ marginTop: 200, marginBottom: 200 }}>
			<ModalAlert data={alert} />

			<Modal show={success} onHide={handleClick}>
				<Modal.Body>
					パスワードの変更が申請されています。<br/>
					次のページでメールアドレスで受け取った確認コードと新しいパスワードを入力してください。<br/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClick}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>

			<Form onSubmit={handleSubmit}>
			<Row>
				<Col sm={{ span: 4, offset: 4 }}>
					<p className="text-center">
						<big>ログインページ</big>
					</p>
				</Col>
			</Row>

			<Row>
				<Col sm={{ span: 4, offset: 4 }}>
					<Form.Group controlId="email">
					        <Form.Control name="email" type="email" placeholder="メールアドレス" required disabled={lock} />
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={{ span: 4, offset: 4 }}>
					<Form.Group controlId="passwd">
					        <Form.Control name="passwd" type="password" placeholder="パスワード" required disabled={lock} />
					</Form.Group>
				</Col>
			</Row>
			<Row style={{ marginTop: 10 }}>
				<Col sm={{ span: 4, offset: 4 }}>
				        <Button variant="dark" type="submit" block disabled={lock} >ログイン</Button>
				        <p style={{ marginTop: 10 }}>パスワードをお忘れの場合は<a href="/forgot-password/">こちら</a>からパスワードの再発行を行ってください。</p>
				</Col>
			</Row>
			</Form>
		</Container>
	);
}

export default withRouter(Default);
