import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const Default = () => {
	
	useEffect(() => {
		
		sessionStorage.setItem('print', 'true');
		window.location.reload();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[true]);
	
	return (
		<div style={{ marginTop: 200, width: '100%', textAlign: 'center'}}>
				<img src="/img/loading.gif" alt="loading" />
		</div>
	);
};

export default withRouter(Default);

