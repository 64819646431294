import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import Table from '../../../Objects/Table';
import { useHistory, useParams, withRouter } from 'react-router-dom';


const Default = () => {

	const history = useHistory();
	const { account_no } = useParams();
	const [load, setLoad] = useState(true);
	const [awsData, setAWSData] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
	
		const getData = async () => {
			
			const resultAWS = await API.AWS.GetAll();
			const itemAWS = resultAWS.items.filter(x=>x.status !== '無効' && x.support_case_flag === true);
			setAWSData(itemAWS);

			let itemSupport = [];

			if(account_no){
				const resultSupport = await API.AWSSupport.GetAll(account_no);
				itemSupport = resultSupport.items;
			}
			else{
				for(const item of itemAWS.concat([{account_no: 'etc'}])){
					const resultSupport = await API.AWSSupport.GetAll(item.account_no);
					itemSupport = itemSupport.concat(resultSupport.items);
				}
			}
			setData(itemSupport);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
	},[account_no]);

	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/support/case/' + row.account_no + '/' + row.case_id)}>
					詳細
				</Button>
			</div>
		);
	};

	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const second       = ('00' + dateObj.getSeconds().toString()).slice(-2);
			const dateText     = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'case_id',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'account_no',
			text: 'AWSアカウント',
			sort: true,
		},
		{
			dataField: 'subject',
			text: '題名',
			sort: true,
		},
		{
			dataField: 'type',
			text: '種別',
			sort: true,
			formatter: (cell) => {
				switch(cell){
					case 'technical':
						return '技術';
					case 'basic':
						return '一般';
					case 'build':
						return '構築';
					default:
						return '';
				};
			},
		},
		{
			dataField: 'user_name',
			text: 'お問合せされた方',
			sort: true,
		},
		{
			dataField: 'status',
			text: 'ステータス',
			sort: true,
		},
		{
			dataField: 'date_add',
			text: '作成日時',
			sort: true,
			formatter: formatDate,
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<Row>
					<Col sm={12}>
						<h4>過去のサポートケース</h4>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Form>
							<Row style={{ marginTop: 20 }}>
								<Col sm={4}>
									<Form.Group controlId="formTechnicalEmail">
										<Form.Label>対象のAWSアカウント</Form.Label>
										<Form.Control as="select" name="account_no" onChange={(e)=>window.location.href = '/support/case/' + e.target.value} value={account_no}>
											<option value={''}>対象のAWSアカウントを選択してください</option>
											{awsData.map((item, index) => {
													return(
														<option key={index} value={item.account_no}>{item.account_name}（{item.account_no}）</option>
													);
												}
											)}
										</Form.Control>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'case_id'} data={data} columns={columns} checkbox={false} sort={ {field: 'date_add', order: 'desc'} } />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
