import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect, FormButton } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { domain_name } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [showRegistrant, setShowRegistrant] = useState([false]);
	const [showContact, setShowContact] = useState([false]);
	const [showNameServer, setShowNameServer] = useState([false]);
	const [showStatus, setShowStatus] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.Domain.Get(domain_name)).item;
			
			if(Object.keys(newData).length){
					setData(newData);
			}
			else{
				setNotfound(true);
			}
			
			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const updateContact = async (event) => {
		
		setShowContact([false]);
		
		try {
			
			if(domain_name){
			
				const newData ={
					domain_name : domain_name,
					contact_id : data.contact_id,
					contact_name :  data.contact_name,
					contact_name_en :  data.contact_name_en,
					contact_email_address :  data.contact_email_address,
					contact_phone_number :  data.contact_phone_number,
					contact_fax_number :  data.contact_fax_number,
					contact_postal_code :  data.contact_postal_code,
					contact_prefecture :  data.contact_prefecture,
					contact_postal_address1 :  data.contact_postal_address1,
					contact_postal_address2 :  data.contact_postal_address2,
					contact_postal_address3 :  data.contact_postal_address3,
					contact_postal_address_en1 :  data.contact_postal_address_en1,
					contact_postal_address_en2 :  data.contact_postal_address_en2,
					contact_postal_address_en3 :  data.contact_postal_address_en3,
					contact_webpage :  data.contact_webpage,
					contact_country_code :  data.contact_country_code,
				};
				
				//更新
				const ref = await API.Domain.Update(newData);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const updateRegistrant = async (event) => {

		setShowContact([false]);
		
		try {
			
			if(domain_name){
			
				const newData ={
					domain_name : domain_name,
					registrant_id : data.registrant_id,
					registrant_name : data.registrant_name,
					registrant_name_en : data.registrant_name_en,
					registrant_representative_name : data.registrant_representative_name,
					registrant_representative_division : data.registrant_representative_division,
					registrant_email_address : data.registrant_email_address,
					registrant_phone_number : data.registrant_phone_number,
					registrant_fax_number : data.registrant_fax_number,
					registrant_postal_code : data.registrant_postal_code,
					registrant_prefecture : data.registrant_prefecture,
					registrant_postal_address1 : data.registrant_postal_address1,
					registrant_postal_address2 : data.registrant_postal_address2,
					registrant_postal_address3 : data.registrant_postal_address3,
					registrant_postal_address_en1 : data.registrant_postal_address_en1,
					registrant_postal_address_en2 : data.registrant_postal_address_en2,
					registrant_postal_address_en3 : data.registrant_postal_address_en3,
					registrant_country_code : data.registrant_country_code,
					registrant_ced_entity_type : data.registrant_ced_entity_type,
					registrant_ced_ident_form : data.registrant_ced_ident_form,
					registrant_ced_otherentity_type : data.registrant_ced_otherentity_type,
					registrant_ced_other_ident_form : data.registrant_ced_other_ident_form,
					registrant_ced_ident_number : data.registrant_ced_ident_number,
					registrant_ced_country_code : data.registrant_ced_country_code,
				};
				
				//更新
				const ref = await API.Domain.Update(newData);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const updateNameServer = async (event) => {
	
		setShowContact([false]);
		
		try {
			
			if(domain_name){
			
				const newData ={
					domain_name : domain_name,
					nameserver : data.nameserver,
				};
				
				//更新
				const ref = await API.Domain.Update(newData);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const updateStatus = async (event) => {
	
		setShowStatus([false]);
		
		try {
			
			if(domain_name){
			
				const newData ={
					domain_name : domain_name,
					status : '対象外',
				};
				
				//更新
				const ref = await API.Domain.Update(newData);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新停止を申請しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const handleContactShow = () => {
		setShowContact([true]);
	};
	
	const handleRegistrantShow = () => {
		setShowRegistrant([true]);
	};
	
	const handleNameServerShow = () => {
		setShowNameServer([true]);
	};
	
	const handleStatusShow = () => {
		setShowStatus([true]);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = data;
		
		//NameServer
		if(name.match(/:/)){
			const object = name.split(":")[0];
			const index = name.split(":")[1];
			newData[object][index] = value;
			console.log(newData.nameserver);
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		setAlert([]);
		setShowContact([false]);
		setShowRegistrant([false]);
		setShowNameServer([false]);
		setShowStatus([false]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/domain/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/domain/' + data.domain_name;
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalContact data={data} show={showContact}  onClick={updateContact} onHide={handleClose} onChange={handleOnChange} />
				<ModalRegistrant data={data} show={showRegistrant} onClick={updateRegistrant} onHide={handleClose} onChange={handleOnChange} />
				<ModalNameServer data={data} show={showNameServer}  onClick={updateNameServer} onHide={handleClose} onChange={handleOnChange} />
				<ModalStatus data={data} show={showStatus}  onClick={updateStatus} onHide={handleClose} />

				<Form name="form">

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'ドメイン名'} name={'domain_name'} defaultValue={data.domain_name} readOnly={true} />
					<hr/>
					<FormText label={'請求アカウント'} name={'customer_no'} defaultValue={data.customer_no ? (data.customer_no + '　' + data.customer_name) : ''} readOnly={true} />
					<FormText label={'ステータス'} name={'status'} defaultValue={data.status}  readOnly={true} />
					<FormText label={'種類'} name={'domain_kbn'} defaultValue={data.domain_kbn} readOnly={true} />
					<FormText label={'種別'} name={'tld_kbn'} defaultValue={data.tld_kbn} readOnly={true} />
					<hr/>
					<FormText label={'初期費用'} name={'init_price_notax'} defaultValue={data.init_price_notax.toLocaleString() + '円'} readOnly={true} />
					<FormText label={'更新費用'} name={'price_notax'} defaultValue={data.price_notax.toLocaleString() + '円'} readOnly={true} />
					<hr/>
					<FormText label={'有効期限'} name={'expiration'} defaultValue={dateShapingDate(data.expiration)} type={'date'} readOnly={true} />
					<FormText label={'契約年数'} name={'contract_years'} defaultValue={data.contract_years.toLocaleString() + '年'} readOnly={true} />
					<FormText label={'次回請求月'} name={'billing_date'} defaultValue={dateShapingMonth(data.billing_date)} type={'month'} readOnly={true} />
					<FormText label={'AUTH CODE'} name={'auth_code'} defaultValue={data.auth_code} readOnly={true} />
					<hr/>
					<FormButton label={'公開連絡窓口'} name={'contact_id'} value={data.contact_id ? data.contact_id : '（Empty）'} onClick={handleContactShow} />
					<FormButton label={'登録者情報'} name={'registrant_id'} value={data.registrant_id ? data.registrant_id : '（Empty）'} onClick={handleRegistrantShow} />
					<FormButton label={'ネームサーバー'} value={'ネームサーバー'} onClick={handleNameServerShow} />
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} defaultValue={dateShapingMonth(data.bill_start)} type={'month'} readOnly={true} />
					<FormText label={'課金停止月'} name={'bill_end'} defaultValue={dateShapingMonth(data.bill_end)} type={'month'} readOnly={true} />
					<hr/>
					<FormButton label={'更新停止'} value={'更新の停止を申し込む'} onClick={handleStatusShow} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};

const ModalContact = (props) => {

	const [show, setShow] = useState(false);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	return (
		<Modal show={show} onHide={props.onHide} size="lg">
			<Modal.Body>
				<Form>
					<FormText label={'ContactID'} name={'contact_id'} value={props.data.contact_id} onChange={props.onChange} />
					<FormText label={'登録者名(日本語)'} name={'contact_name'} value={props.data.contact_name} onChange={props.onChange} />
					<FormText label={'登録者名(英語)'} name={'contact_name_en'} value={props.data.contact_name_en} onChange={props.onChange} />
					<FormText label={'E-mail'} name={'contact_email_address'} value={props.data.contact_email_address} type={'email'} onChange={props.onChange} />
					<FormText label={'電話番号'} name={'contact_phone_number'} value={props.data.contact_phone_number} type={'tel'} onChange={props.onChange} />
					<FormText label={'FAX番号'} name={'contact_fax_number'} value={props.data.contact_fax_number} type={'tel'} onChange={props.onChange} />
					<FormText label={'郵便番号'} name={'contact_postal_code'} value={props.data.contact_postal_code} type={'zip'} onChange={props.onChange} />
					<FormText label={'都道府県'} name={'contact_prefecture'} value={props.data.contact_prefecture} onChange={props.onChange} />
					<FormText label={'住所(日本語)１'} name={'contact_postal_address1'} value={props.data.contact_postal_address1} onChange={props.onChange} />
					<FormText label={'住所(日本語)２'} name={'contact_postal_address2'} value={props.data.contact_postal_address2} onChange={props.onChange} />
					<FormText label={'住所(日本語)３'} name={'contact_postal_address3'} value={props.data.contact_postal_address3} onChange={props.onChange} />
					<FormText label={'住所(英語)１'} name={'contact_postal_address_en1'} value={props.data.contact_postal_address_en1} onChange={props.onChange} />
					<FormText label={'住所(英語)２'} name={'contact_postal_address_en2'} value={props.data.contact_postal_address_en2} onChange={props.onChange} />
					<FormText label={'住所(英語)３'} name={'contact_postal_address_en3'} value={props.data.contact_postal_address_en3} onChange={props.onChange} />
					<FormText label={'WebPage'} name={'contact_webpage'} value={props.data.contact_webpage} type={'url'} onChange={props.onChange} />
					<FormText label={'CountryCode'} name={'contact_country_code'} value={props.data.contact_country_code} onChange={props.onChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} disabled>更新する</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const ModalRegistrant = (props) => {

	const [show, setShow] = useState(false);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);

	return (
		<Modal show={show} onHide={props.onHide} size="lg">
			<Modal.Body>
				<Form>

					<FormText label={'RegistrantID'} name={'registrant_id'} value={props.data.registrant_id} onChange={props.onChange} />
					<FormText label={'登録者名(日本語)'} name={'registrant_name'} value={props.data.registrant_name} onChange={props.onChange} />
					<FormText label={'登録者名(英語)'} name={'registrant_name_en'} value={props.data.registrant_name_en} onChange={props.onChange} />
					<FormText label={'登録担当者'} name={'registrant_representative_name'} value={props.data.registrant_representative_name} onChange={props.onChange} />
					<FormText label={'部署名'} name={'registrant_representative_division'} value={props.data.registrant_representative_division} onChange={props.onChange} />
					<FormText label={'E-mail'} name={'registrant_email_address'} value={props.data.registrant_email_address} type={'email'} onChange={props.onChange} />
					<FormText label={'電話番号'} name={'registrant_phone_number'} value={props.data.registrant_phone_number} type={'tel'} onChange={props.onChange} />
					<FormText label={'FAX番号'} name={'registrant_fax_number'} value={props.data.registrant_fax_number} type={'tel'} onChange={props.onChange} />
					<FormText label={'郵便番号'} name={'registrant_postal_code'} value={props.data.registrant_postal_code} type={'zip'} onChange={props.onChange} />
					<FormText label={'都道府県'} name={'registrant_prefecture'} value={props.data.registrant_prefecture} onChange={props.onChange} />
					<FormText label={'住所(日本語)１'} name={'registrant_postal_address1'} value={props.data.registrant_postal_address1} onChange={props.onChange} />
					<FormText label={'住所(日本語)２'} name={'registrant_postal_address2'} value={props.data.registrant_postal_address2} onChange={props.onChange} />
					<FormText label={'住所(日本語)３'} name={'registrant_postal_address3'} value={props.data.registrant_postal_address3} onChange={props.onChange} />
					<FormText label={'住所(英語)１'} name={'registrant_postal_address_en1'} value={props.data.registrant_postal_address_en1} onChange={props.onChange} />
					<FormText label={'住所(英語)２'} name={'registrant_postal_address_en2'} value={props.data.registrant_postal_address_en2} onChange={props.onChange} />
					<FormText label={'住所(英語)３'} name={'registrant_postal_address_en3'} value={props.data.registrant_postal_address_en3} onChange={props.onChange} />
					<FormText label={'CountryCode'} name={'registrant_country_code'} value={props.data.registrant_country_code} onChange={props.onChange} />
					<FormSelect label={'CEDコンタクト種別'} name={'registrant_ced_entity_type'} defaultValue={props.data.registrant_ced_entity_type} data={ [ '法人/企業', '協同組合', '合資会社/合名会社', '政府機関/地方自治体', '政党/労働組合', '企業合同/協会/結社', '公共機関/公共団体', '個人', 'その他' ] } readOnly={true} />
					<FormText label={'その他の場合'} name={'registrant_ced_ident_form'} defaultValue={props.data.registrant_ced_ident_form} readOnly={true} />
					<FormSelect label={'CEDの証明方法'} name={'registrant_ced_otherentity_type'} defaultValue={props.data.registrant_ced_otherentity_type} data={ [ '法人登記、会社設立許可書等', '法令・判決・法律等による設立許可書', '団体の登記簿等', '政治団体の証明書', 'パスポートまたは健康保険書番号', 'その他' ] } readOnly={true} />
					<FormText label={'その他の場合'} name={'registrant_ced_other_ident_form'} defaultValue={props.data.registrant_ced_other_ident_form} readOnly={true} />
					<FormText label={'証明番号'} name={'registrant_ced_ident_number'} defaultValue={props.data.registrant_ced_ident_number} readOnly={true} />
					<FormText label={'証明方法が発行された国'} name={'registrant_ced_country_code'} defaultValue={props.data.registrant_ced_country_code} readOnly={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} disabled>更新する</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const ModalNameServer = (props) => {

	const [show, setShow] = useState(false);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	return (
		<Modal show={show} onHide={props.onHide} size="lg">
			<Modal.Body>
				<Form>
					<FormText label={'ネームサーバー'} name={'nameserver:0'} value={props.data.nameserver[0]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:1'} value={props.data.nameserver[1]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:2'} value={props.data.nameserver[2]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:3'} value={props.data.nameserver[3]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:4'} value={props.data.nameserver[4]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:5'} value={props.data.nameserver[5]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:6'} value={props.data.nameserver[6]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:7'} value={props.data.nameserver[7]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:8'} value={props.data.nameserver[8]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:9'} value={props.data.nameserver[9]} onChange={props.onChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} disabled>更新する</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const ModalStatus = (props) => {

	const [show, setShow] = useState(false);
	const [flag, setFlag] = useState(true);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		const expiration = Number(props.data.expiration);
		const now_time = Math.floor((new Date()).getTime() / 1000);
		const exp_days = Math.floor((expiration - now_time) / (60 * 60 * 24));
		const status = props.data.status;
		
		if(exp_days > 65 && status === '更新対象'){
			setFlag(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	return (
		<Modal show={show} onHide={props.onHide} size="lg">
			<Modal.Body>
				<Form>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        ドメイン名の残り有効期間が65日を切る場合、ポータルサイトからは停止を申し込めません。
						        その際はお手数ですが、営業窓口までお問い合わせください。
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} disabled={flag} >次回更新を停止する</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(Default);
