import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Server = {};

Server.Get = async (server_no) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getServer, { server_no : server_no }));
		const item = ref.data.getServer;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Server.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanServer));
		let items = ref.data.scanServer.items;
		while (ref.data.scanServer.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanServer, { nextToken: ref.data.scanServer.nextToken }));
			items = items.concat(ref.data.scanServer.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Server.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateServer, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


Server.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createServer, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Server.Delete = async (server_no) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteServer, { server_no: server_no }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

export default Server;


