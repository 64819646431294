import {
	Container,
	Row,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

const Default = () => {
	return (
		<div>
			<Container>
				<Row style={{marginTop: '50px'}}>
					<h3>サービス情報 <small>ご覧になりたいサービスを選んでください。</small></h3>
				</Row>

				<div className="row" style={{marginBottom: '40px'}}>
					<div className="col-sm-3 col-md-3">
						<div className="text-center">
							<a href="/domain/"><img src="./img/onocomm_ps_pict1.png" alt="ドメイン名" className="img-rounded"/></a>
							ドメイン名
						</div>
					</div>
					<div className="col-sm-3 col-md-3">
						<div className="text-center">
							<a href="/ssl/"><img src="./img/onocomm_ps_pict2.png" alt="SSLサーバー証明書" className="img-rounded"/></a>
							サーバー証明書
						</div>
					</div>
					<div className="col-sm-3 col-md-3">
						<div className="text-center">
							<a href="/aws/"><img src="./img/onocomm_ps_pict5.png" alt="AWSアカウント" className="img-rounded"/></a>
							AWSクラウド
						</div>
					</div>
					<div className="col-sm-3 col-md-3">
						<div className="text-center">
							<a href="/server/"><img src="./img/onocomm_ps_pict7.png" alt="サーバー" className="img-rounded"/></a>
							サーバー
						</div>
					</div>
					<div className="col-sm-3 col-md-3">
						<div className="text-center">
							<a href="/mail/"><img src="./img/onocomm_ps_pict4.png" alt="メール" className="img-rounded"/></a>
							メール
						</div>
					</div>
					<div className="col-sm-3 col-md-3">
						<div className="text-center">
							<a href="/ns53/"><img src="./img/onocomm_ps_pict3.png" alt="NS53" className="img-rounded"/></a>
							NS53
						</div>
					</div>
					<div className="col-sm-3 col-md-3">
						<div className="text-center">
							<a href="/bill/"><img src="./img/onocomm_ps_pict8.png" alt="月額課金" className="img-rounded"/></a>
							月額課金
						</div>
					</div>
					<div className="col-sm-3 col-md-3">
						<div className="text-center">
							<a href="/support/"><img src="./img/onocomm_ps_pict6.png" alt="サポート" className="img-rounded"/></a>
							サポート
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default withRouter(Default);
