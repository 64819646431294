//パスワードの再申請の確認コードを受け付ける

import { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
	Breadcrumb,
} from 'react-bootstrap';
import { useHistory, useParams, withRouter } from 'react-router-dom';
//import { AuditLog } from '../../Objects/AuditLog';
import { ModalAlert } from '../../Objects/Modal';

const Default = (props) => {

	const history = useHistory();
	const { email } = useParams();
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [success, setSuccess] = useState(false);

	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		//更新処理
		try{
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//パスワード再設定
			const result = await Auth.forgotPasswordSubmit(formObj.email.value, formObj.code.value, formObj.passwd.value);
			
			//debug
			console.log(result);
			
			//成功
			setSuccess(true);
		}
		catch(err) {
			setAlert([err.message]);
		}
		
		//ロック解除
		setLock(false);
		
	};
	
	const handleClick = async (event) => {
		window.location.href='/';
	};
	
	return (
		<div>
			<Breadcrumb>
				<Breadcrumb.Item href="/">ログイン</Breadcrumb.Item>
				<Breadcrumb.Item href="/forgot-password/">パスワード再登録（確認コードの発行）</Breadcrumb.Item>
				<Breadcrumb.Item active>パスワード再登録</Breadcrumb.Item>
			</Breadcrumb>
			<Container style={{ marginTop: 200, marginBottom: 200 }}>
				<ModalAlert data={alert} />
				
				<Modal show={success} onHide={handleClick}>
					<Modal.Body>
						パスワードを再登録しました。<br/>
						もう一度ログインを行ってください。<br/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleClick}>
							OK
						</Button>
					</Modal.Footer>
				</Modal>
				
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center">
								<big>パスワード再登録</big><br/>
							</p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center">
								メールアドレスで受け取った確認コードと新しいパスワードを入力します。
							</p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 6, offset: 3 }} style={{ marginBottom: 50 }}>
							パスワードは8〜255文字で、大文字1文字、小文字1文字、数字1文字以上である必要があります。
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="email">
							        <Form.Control name="email" type="email" placeholder="メールアドレス" defaultValue={email ? email : ''} required disabled={lock} autoComplete="off" />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="code">
							        <Form.Control name="code" type="text" placeholder="確認コード" required disabled={lock} autoComplete="off" />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="passwd">
							        <Form.Control name="passwd" type="password" placeholder="新しいパスワード" required minLength="8" maxLength="255" disabled={lock} autoComplete="off" />
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >パスワード再登録</Button>
						</Col>
					</Row>
					
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="secondary" type="button" block onClick={ ()=>{ history.goBack(); } } disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		</div>
	);
}

export default withRouter(Default);
