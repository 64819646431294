import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Order = {};

Order.Get = async (order_no) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getOrder, { order_no : order_no }));
		const item = ref.data.getOrder;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Order.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanOrder));
		let items = ref.data.scanOrder.items;
		while (ref.data.scanOrder.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanOrder, { nextToken: ref.data.scanOrder.nextToken }));
			items = items.concat(ref.data.scanOrder.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Order.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createOrder, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Order;


