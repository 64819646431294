import { useState, useEffect  } from 'react';
import {
	Container,
	Row,
	Col,
	Image,
	Card,
	Button,
} from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const { user_id } = useParams();
	const [load, setLoad] = useState(true);
	const [data, setData] = useState({});
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {

		const userData = {};

		userData.AWS = [];

		//<option value={'a43733bd-9c04-44a2-b1f4-b7e32bdbd6ae:斧山　洋一'}>斧山　洋一</option>
		//<option value={'56d02973-3285-444f-9fc9-c859021bd4e5:滝口　泰之'}>滝口　泰之</option>
		//<option value={'bc9a089c-2172-4096-b523-1b5e8e0bfe20:東方　昭洋'}>東方　昭洋</option>

		//斧山　洋一
		if(user_id === 'a43733bd-9c04-44a2-b1f4-b7e32bdbd6ae'){

			userData.profile_img = 'https://images.credly.com/images/f72163bd-2091-412d-a100-cee8b2e12c30/IMG_0004.jpg';
			userData.name = 'Yoichi Onoyama';

			userData.AWS.push({
				name : 'CLF',
				title : 'AWS Certified Cloud Practitioner',
				issued : '2019-06-20',
				img : 'https://images.credly.com/size/680x680/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png',
				url : 'https://www.credly.com/earner/earned/badge/a1b11594-2948-4255-8740-760a02faa527',
			});

			userData.AWS.push({
				name : 'AIF',
				title : 'AWS Certified AI Practitioner',
				issued : '2024-10-24',
				img : 'https://images.credly.com/size/680x680/images/4d4693bb-530e-4bca-9327-de07f3aa2348/image.png',
				url : 'https://www.credly.com/earner/earned/badge/709976ee-b95c-4e25-a9ba-a0cbd76da86d',
			});
			
			userData.AWS.push({
				name : 'SOA',
				title : 'AWS Certified SysOps Administrator – Associate',
				issued : '2024-09-06',
				img : 'https://images.credly.com/size/680x680/images/f0d3fbb9-bfa7-4017-9989-7bde8eaf42b1/image.png',
				url : 'https://www.credly.com/earner/earned/badge/35159dd7-3e9f-4929-9e7b-35061dc43762',
			});

			userData.AWS.push({
				name : 'DVA',
				title : 'AWS Certified Developer – Associate',
				issued : '2024-09-04',
				img : 'https://images.credly.com/size/680x680/images/b9feab85-1a43-4f6c-99a5-631b88d5461b/image.png',
				url : 'https://www.credly.com/earner/earned/badge/7154ab48-4520-46a0-8db1-05a294b61f88',
			});

			userData.AWS.push({
				name : 'SAA',
				title : 'AWS Certified Solutions Architect – Associate',
				issued : '2018-05-16',
				img : 'https://images.credly.com/size/680x680/images/0e284c3f-5164-4b21-8660-0d84737941bc/image.png',
				url : 'https://www.credly.com/earner/earned/badge/284e2513-62bc-4205-bec5-1873c754de55',
			});

			userData.AWS.push({
				name : 'DEA',
				title : 'AWS Certified Data Engineer – Associate',
				issued : '2024-09-13',
				img : 'https://images.credly.com/size/680x680/images/e5c85d7f-4e50-431e-b5af-fa9d9b0596e7/image.png',
				url : 'https://www.credly.com/earner/earned/badge/997c8ab2-6f98-4b29-a29f-099ef7d834e1',
			});

			userData.AWS.push({
				name : 'MLA',
				title : 'AWS Certified Machine Learning Engineer – Associate',
				issued : '2024-11-01',
				img : 'https://images.credly.com/size/680x680/images/1a634b4e-3d6b-4a74-b118-c0dcb429e8d2/image.png',
				url : 'https://www.credly.com/earner/earned/badge/645bffd4-34a7-484c-a611-b71847ac0c68',
			});

			userData.AWS.push({
				name : 'SAP',
				title : 'AWS Certified Solutions Architect – Professional',
				issued : '2024-02-29',
				img : 'https://images.credly.com/size/680x680/images/2d84e428-9078-49b6-a804-13c15383d0de/image.png',
				url : 'https://www.credly.com/earner/earned/badge/e9285cf9-d40e-4a66-b79a-eb21019639cf',
			});

			userData.AWS.push({
				name : 'DOP',
				title : 'AWS Certified DevOps Engineer – Professional',
				issued : '2024-09-20',
				img : 'https://images.credly.com/size/680x680/images/bd31ef42-d460-493e-8503-39592aaf0458/image.png',
				url : 'https://www.credly.com/earner/earned/badge/f66751d2-935c-470c-8ea7-024780232c6b',
			});

			userData.AWS.push({
				name : 'ANS',
				title : 'AWS Certified Advanced Networking – Specialty',
				issued : '2024-09-27',
				img : 'https://images.credly.com/size/680x680/images/4d08274f-64c1-495e-986b-3143f51b1371/image.png',
				url : 'https://www.credly.com/earner/earned/badge/51c8614d-67d4-4ac5-9ea2-9317247b7a1c',
			});

			userData.AWS.push({
				name : 'MLS',
				title : 'AWS Certified Machine Learning – Specialty',
				issued : '2024-10-18',
				img : 'https://images.credly.com/size/680x680/images/778bde6c-ad1c-4312-ac33-2fa40d50a147/image.png',
				url : 'https://www.credly.com/earner/earned/badge/4fb86923-72d9-4142-a68c-b55cba76428a',
			});

			userData.AWS.push({
				name : 'SCS',
				title : 'AWS Certified Security – Specialty',
				issued : '2024-08-16',
				img : 'https://images.credly.com/size/680x680/images/53acdae5-d69f-4dda-b650-d02ed7a50dd7/image.png',
				url : 'https://www.credly.com/earner/earned/badge/6d43816a-67fa-4bbe-a46e-7dea46d44b81',
			});
		}
		//滝口　泰之
		else if(user_id === '56d02973-3285-444f-9fc9-c859021bd4e5'){
			userData.profile_img = '/img/no_image.jpg';
			userData.name = 'Yasuyuki Takiguchi';
		}
		//東方　昭洋
		else if(user_id === 'bc9a089c-2172-4096-b523-1b5e8e0bfe20'){
			userData.profile_img = '/img/no_image.jpg';
			userData.name = 'Akihiro Higashikata';
		}

		if(!user_id || !userData){
			setNotfound(true);
			setLoad(false);
			return;
		}

		setData(userData);

		setLoad(false);

		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<Row className="justify-content-center">
					<Col sm={12} className="text-center">
						<Image src={data.profile_img} roundedCircle style={{ width: '100px', height: '100px' }} />
						<p>{data.name}</p>
					</Col>
				</Row>
				<Row className="justify-content-center">
					{data.AWS.map((item) => (
						<Col key={item.name} sm={6} md={4} lg={3} className="d-flex justify-content-center mb-4">
							<Card style={{ width: '18rem' }}>
								<Card.Img 
									variant="top" 
									src={item.img} 
									style={{ width: '150px', height: '150px', objectFit: 'cover', margin: '0 auto' }} 
								/>
								<Card.Body>
									<Card.Title>{item.title}</Card.Title>
									<Card.Text>
										issued: {item.issued}
									</Card.Text>
									<Button variant="secondary" onClick={() => { window.location.href = item.url }}>Digital Badge</Button>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</Container>
		);
	}
};


export default withRouter(Default);
