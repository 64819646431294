
const Default = () => {
	return (
		<div>
			<link href="/css/bootstrap.min.css" rel="stylesheet"/>
			<link href="/css/react-bootstrap-table2.css" rel="stylesheet"/>
			<link href="/css/react-bootstrap-table2-paginator.min.css" rel="stylesheet"/>
		</div>
	);
};

export default Default;

