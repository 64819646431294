import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';
import NotFound from '../../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { ns53_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [count, setCount] = useState(0);
	const [refresh, setRefresh] = useState([true]);
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.NS53.Get(ns53_no)).item;
			
			if(Object.keys(newData).length){
				setData(newData);
			}
			else{
				setNotfound(true);
			}

			//件数の取得
			const ref = await API.NS53.DomainGetAll(ns53_no);
			setCount(ref.items ? ref.items.length : 0);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	const handleSubmit = async (event) => {
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		try {
		
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			//console.log(count);
			//console.log(data.domain_num);
			if(data.domain_num <= count){
				throw new Error('登録できる上限数を超えています。');
			}

			if(ns53_no && data.customer_no && data.name){
				
				//ゾーン追加
				const ref1 = await API.NS53.DomainCreate({
					ns53_no: ns53_no,
					customer_no: data.customer_no,
					name: data.name,
				});
				//エラー確認
				if(ref1.error){
					throw new Error('入力エラーがありました。' + ref1.error);
				}
				
				//SOA追加
				const ref2 = await API.NS53.RecordCreate({
					ns53_no: ns53_no,
					customer_no: data.customer_no,
					domain_name: data.name,
					name: data.name,
					type: 'SOA',
					content: 'a.ns53.jp postmaster.onocomm.co.jp 1 3600 3600 3600 3600',
					ttl: 600,
				});
				//エラー確認
				if(ref2.error){
					throw new Error('入力エラーがありました。' + ref2.error);
				}
				
				//NS追加
				const ref3 = await API.NS53.RecordCreate({
					ns53_no: ns53_no,
					customer_no: data.customer_no,
					domain_name: data.name,
					name: data.name,
					type: 'NS',
					content: 'a.ns53.jp\nb.ns53.jp',
					ttl: 600,
				});
				//エラー確認
				if(ref3.error){
					throw new Error('入力エラーがありました。' + ref3.error);
				}
				
				throw new Error('登録しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		//const type = event.target.type;
		
		const newData = data;
		
		newData[name] = value;
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='登録しました。'){
			window.location.href = '/ns53/' + ns53_no + '/' +data.name + '/';
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Form onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>ゾーン登録</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'契約ID'} name={'ns53_no'} defaultValue={data.ns53_no} readOnly={true} />
					<hr/>
					<FormText label={'ドメイン名'} name={'name'} value={data.name} pattern="^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" onChange={handleOnChange} required={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block>登録する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
