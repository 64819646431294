import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, useParams, withRouter } from 'react-router-dom';


const Default = () => {

	const history = useHistory();
	const { server_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);

	useEffect(() => {
	
		const getData = async () => {
			
			//件数の取得
			const newData = (await API.Server.Get(server_no)).item;

			if(Object.keys(newData).length){
				//データセット
				setData(newData.op);
				setView(newData.op);
				
			}
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const columns = [
		{
			dataField: 'details',
			text: '内容',
			sort: true,
		},
		{
			dataField: 'price_notax',
			text: '単価',
			sort: true,
			formatter: (cell) => { return(cell ? cell.toLocaleString() + '円' : ''); },
			classes: 'text-right',
		},
		{
			dataField: 'piece',
			text: '個数',
			sort: true,
			formatter: (cell, row) => { return(cell ? cell.toLocaleString() + row.piece_type : ''); },
			classes: 'text-right',
		},
		{
			dataField: 'sub_price_notax',
			text: '合計',
			sort: true,
			formatter: (cell) => { return(cell ? cell.toLocaleString() + '円' : ''); },
			classes: 'text-right',
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h4>サーバーのオプション</h4>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1}>
								        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
								</Col>
							</Row>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'uuid'} data={view} columns={columns} checkbox={false} sort={ {field: 'sort', order: 'asc'} } />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
