import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const MailWhitelist = {};

MailWhitelist.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanMailWhitelist));
		let items = ref.data.scanMailWhitelist.items;
		while (ref.data.scanMailWhitelist.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanMailWhitelist, { nextToken: ref.data.scanMailWhitelist.nextToken }));
			items = items.concat(ref.data.scanMailWhitelist.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

MailWhitelist.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createMailWhitelist, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

MailWhitelist.Delete = async (ip) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteMailWhitelist, { ip }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

export default MailWhitelist;


